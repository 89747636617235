import type { Skill } from '@seek/ca-graphql-schema/types';
import type { JobDetailsView } from '@seek/job-details-view';
import type React from 'react';

import { useSkillsForJDV } from 'src/hooks/useSkills/useSkillsForJDV';
import { useSkillsMutations } from 'src/hooks/useSkills/useSkillsMutations';
import { logger } from 'src/modules/logger';

const SKILL_LIMIT = 50; // todo confirm skill limit value
const MINIMUM_MATCHES = 3;

export const useJDVSkillsMatchesProps = (
  jobId: string,
): React.ComponentProps<typeof JobDetailsView>['qualities'] => {
  const { addSkill, removeSkill } = useSkillsMutations(SKILL_LIMIT);
  const { qualities, hasResume, error, loading } = useSkillsForJDV(jobId);
  const { matched, inferred, unmatched } = qualities ?? {};

  if (error) {
    logger.error(error, 'useJDVSkillsMatches.ts');
  }

  const shouldShowSkillsMatch =
    (matched?.length ?? 0) +
      (inferred?.length ?? 0) +
      (unmatched?.length ?? 0) >=
    MINIMUM_MATCHES;

  return {
    matched: matched as Skill[],
    inferred: inferred as Skill[],
    unmatched: unmatched as Skill[],
    shouldShowSkillsMatch,
    hasResume,
    isLoadingQualities: loading,
    error: error ? 'NetworkError' : undefined,
    onAddQuality: addSkill,
    onRemoveQuality: removeSkill,
  };
};
