
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgTm93In0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgTm93In0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiQmV0dGVyIGpvYiBtYXRjaGVzLiIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSIsIkdldCB0aXBzIGFuZCB0b29scyI6IkdldCB0aXBzIGFuZCB0b29scyIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIiwiSGlyZSBmb3IgRlJFRSI6IkhpcmUgZm9yIEZSRUUifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiUGVtaWxpaGFuIHBla2VyamFhbiB5YW5nIGxlYmloIGJhaWsgZGFyaXBhZGEgc2ViZWx1bW55YSIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiBrYXJpciBkYW4gZ2FqaSBsZWJpaCBiYWlrIiwiR2V0IHRpcHMgYW5kIHRvb2xzIjoiRGFwZXRpbiB0aXBzICYgZml0dXJueWEiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIFBlbmNvY29rYW4ga2FuZGlkYXQgbGViaWggYmFpayIsIkhpcmUgZm9yIEZSRUUiOiJNdWxhaSBzZWthcmFuZywgR1JBVElTISJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiUGVtaWxpaGFuIHBla2VyamFhbiB5YW5nIGxlYmloIGJhaWsgZGFyaXBhZGEgc2ViZWx1bW55YSIsIlwiSGVsbG9cIiB0byBhIGJldHRlciBjYXJlZXIgYW5kIHNhbGFyeSI6IlwiSGVsbG9cIiBrYXJpciBkYW4gZ2FqaSBsZWJpaCBiYWlrIiwiR2V0IHRpcHMgYW5kIHRvb2xzIjoiRGFwZXRpbiB0aXBzICYgZml0dXJueWEiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIFBlbmNvY29rYW4ga2FuZGlkYXQgbGViaWggYmFpayIsIkhpcmUgZm9yIEZSRUUiOiJNdWxhaSBzZWthcmFuZywgR1JBVElTISJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoi4Lie4Lia4LiH4Liy4LiZ4LiX4Li14LmI4LmD4LiK4LmI4LmE4LiU4LmJ4LiU4Li14LiB4Lin4LmI4Liy4LiX4Li14LmI4LmA4LiE4LiiIiwiXCJIZWxsb1wiIHRvIGEgYmV0dGVyIGNhcmVlciBhbmQgc2FsYXJ5IjoiXCJIZWxsb1wiIOC4nuC4muC4geC4seC4muC4h+C4suC4meC5geC4peC4sOC5gOC4h+C4tOC4meC5gOC4lOC4t+C4reC4meC4l+C4teC5iOC4lOC4teC4geC4p+C5iOC4siIsIkdldCB0aXBzIGFuZCB0b29scyI6IuC4leC4seC4p+C4iuC5iOC4p+C4ouC5g+C4meC4geC4suC4o+C4q+C4suC4h+C4suC4mSDguITguKXguLTguIEiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIOC4hOC5ieC4meC4q+C4suC4hOC4meC4l+C4teC5iOC5g+C4iuC5iOC5hOC4lOC5ieC4lOC4teC4geC4p+C5iOC4suC4l+C4teC5iOC5gOC4hOC4oiIsIkhpcmUgZm9yIEZSRUUiOiLguKvguLLguITguJkg4Lif4Lij4Li1ISJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoi4Lie4Lia4LiH4Liy4LiZ4LiX4Li14LmI4LmD4LiK4LmI4LmE4LiU4LmJ4LiU4Li14LiB4Lin4LmI4Liy4LiX4Li14LmI4LmA4LiE4LiiIiwiXCJIZWxsb1wiIHRvIGEgYmV0dGVyIGNhcmVlciBhbmQgc2FsYXJ5IjoiXCJIZWxsb1wiIOC4nuC4muC4geC4seC4muC4h+C4suC4meC5geC4peC4sOC5gOC4h+C4tOC4meC5gOC4lOC4t+C4reC4meC4l+C4teC5iOC4lOC4teC4geC4p+C5iOC4siIsIkdldCB0aXBzIGFuZCB0b29scyI6IuC4leC4seC4p+C4iuC5iOC4p+C4ouC5g+C4meC4geC4suC4o+C4q+C4suC4h+C4suC4mSDguITguKXguLTguIEiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIOC4hOC5ieC4meC4q+C4suC4hOC4meC4l+C4teC5iOC5g+C4iuC5iOC5hOC4lOC5ieC4lOC4teC4geC4p+C5iOC4suC4l+C4teC5iOC5gOC4hOC4oiIsIkhpcmUgZm9yIEZSRUUiOiLguKvguLLguITguJkg4Lif4Lij4Li1ISJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiW+G6nuG6veG6veG6veG5r+G5r+G6veG6veG6vcWZIMS1w7bDtsO2w58gbcyCxIPEg8SD4bmvw6fhuKnhur3hur3hur3FoS5dIiwiXCJIZWxsb1wiIHRvIGEgYmV0dGVyIGNhcmVlciBhbmQgc2FsYXJ5IjoiW1wi4bio4bq94bq94bq9xprGmsO2w7bDtlwiIOG5r8O2w7bDtiDEg8SDxIMgw5/hur3hur3hur3hua/hua/hur3hur3hur3FmSDDp8SDxIPEg8WZ4bq94bq94bq94bq94bq94bq9xZkgxIPEg8SD4LiB4Li14LmJxowgxaHEg8SDxIPGmsSDxIPEg8WZw73DvcO9XSIsIkdldCB0aXBzIGFuZCB0b29scyI6IlvHpuG6veG6veG6veG5ryDhua/DrMOsw6zGpcWhIMSDxIPEg+C4geC4teC5icaMIOG5r8O2w7bDtsO2w7bDtsaaxaFdIiwiXCJIZWxsb1wiIHRvIGJldHRlciB0YWxlbnQgbWF0Y2hlcyI6IltcIuG4qOG6veG6veG6vcaaxprDtsO2w7ZcIiDhua/DtsO2w7Ygw5/hur3hur3hur3hua/hua/hur3hur3hur3FmSDhua/Eg8SDxIPGmuG6veG6veG6veC4geC4teC5ieG5ryBtzILEg8SDxIPhua/Dp+G4qeG6veG6veG6vcWhXSIsIkhpcmUgZm9yIEZSRUUiOiJb4biow6zDrMOsxZnhur3hur3hur0gxpLDtsO2w7bFmSDGkcWYw4vDi10ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCZXR0ZXIgam9iIG1hdGNoZXMuIjoiW+G6nuG6veG6veG6veG5r+G5r+G6veG6veG6vcWZIMS1w7bDtsO2w58gbcyCxIPEg8SD4bmvw6fhuKnhur3hur3hur3FoS5dIiwiXCJIZWxsb1wiIHRvIGEgYmV0dGVyIGNhcmVlciBhbmQgc2FsYXJ5IjoiW1wi4bio4bq94bq94bq9xprGmsO2w7bDtlwiIOG5r8O2w7bDtiDEg8SDxIMgw5/hur3hur3hur3hua/hua/hur3hur3hur3FmSDDp8SDxIPEg8WZ4bq94bq94bq94bq94bq94bq9xZkgxIPEg8SD4LiB4Li14LmJxowgxaHEg8SDxIPGmsSDxIPEg8WZw73DvcO9XSIsIkdldCB0aXBzIGFuZCB0b29scyI6IlvHpuG6veG6veG6veG5ryDhua/DrMOsw6zGpcWhIMSDxIPEg+C4geC4teC5icaMIOG5r8O2w7bDtsO2w7bDtsaaxaFdIiwiXCJIZWxsb1wiIHRvIGJldHRlciB0YWxlbnQgbWF0Y2hlcyI6IltcIuG4qOG6veG6veG6vcaaxprDtsO2w7ZcIiDhua/DtsO2w7Ygw5/hur3hur3hur3hua/hua/hur3hur3hur3FmSDhua/Eg8SDxIPGmuG6veG6veG6veC4geC4teC5ieG5ryBtzILEg8SDxIPhua/Dp+G4qeG6veG6veG6vcWhXSIsIkhpcmUgZm9yIEZSRUUiOiJb4biow6zDrMOsxZnhur3hur3hur0gxpLDtsO2w7bFmSDGkcWYw4vDi10ifQ==!"
        )
      )
      });
  
      export { translations as default };
    