import { useTranslations } from '@vocab/react';
import {
  Stack,
  Heading,
  TextLink,
  Text,
  Inline,
  Box,
  TooltipRenderer,
  IconInfo,
} from 'braid-design-system';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import AnimatedHeight from 'src/components/AnimatedHeight/AnimatedHeight';
import {
  isBrowserAnalyticsFacade,
  useAnalyticsFacade,
} from 'src/modules/AnalyticsFacade';
import { saveSolReferencesForLoggedInHomeRecsAction } from 'src/store/results';
import { useLocalisedLinks } from 'src/utils/melwaysHelper';

import { CardsStack } from '../SharedComponent/CardsStack/CardsStack';
import { ErrorState } from '../SharedComponent/ErrorState/ErrorState';
import { MessageWrapper } from '../SharedComponent/MessageWrapper/MessageWrapper';

import translations from './.vocab';
import { LoadingRecommendations } from './LoadingRecommendations';
import { RecommendedJobCard } from './RecommendedJobCard';
import {
  useHomeRecommendations,
  type RecommendedJobs,
} from './useHomeRecommendations';

const LOADING_SKELETON_COUNT = 3;

const NoRecommendations = () => {
  const profileLink = useLocalisedLinks({
    path: '/profile/me',
  });
  const analyticsFacade = useAnalyticsFacade();

  const profileLinkOnClick = useCallback(() => {
    analyticsFacade.linkClicked({
      href: profileLink,
      linkName: 'dashboard-recommended-jobs-profile',
    });
  }, [analyticsFacade, profileLink]);

  const { t } = useTranslations(translations);

  return (
    <MessageWrapper>
      {t('Update your profile or start searching', {
        Link: (children) => (
          <TextLink
            weight="weak"
            target="_blank"
            href={profileLink}
            onClick={profileLinkOnClick}
          >
            {children}
          </TextLink>
        ),
      })}
    </MessageWrapper>
  );
};

const useTrackingRecommendedJobs = (
  recommendedJobs: RecommendedJobs | null | undefined,
) => {
  const [isViewedEventSent, setIsViewedEventSent] = useState(false);
  const analyticsFacade = useAnalyticsFacade();
  const dispatch = useDispatch();

  useEffect(() => {
    if (recommendedJobs && !isViewedEventSent) {
      if (isBrowserAnalyticsFacade(analyticsFacade)) {
        analyticsFacade.recommendedJobsImpression({
          solMetadata: recommendedJobs.solMetadata,
        });
      }
      setIsViewedEventSent(true);
    }
  }, [
    analyticsFacade,
    isViewedEventSent,
    recommendedJobs,
    setIsViewedEventSent,
  ]);

  useEffect(() => {
    const globalJobs = recommendedJobs?.globalJobs ?? [];
    if (globalJobs.length) {
      dispatch(saveSolReferencesForLoggedInHomeRecsAction(globalJobs));
    }
  }, [dispatch, recommendedJobs]);
};

const TopRecommendationsContent = () => {
  const { topJobs, loading, error } = useHomeRecommendations();

  if (loading) {
    return (
      <LoadingRecommendations
        length={LOADING_SKELETON_COUNT}
        keyPrefix="topRecs"
      />
    );
  }

  if (error) {
    return <ErrorState section="recommendedJobs" />;
  }

  if (!topJobs.length) {
    return <NoRecommendations />;
  }

  return (
    <CardsStack data={{ automation: 'topRecommendedJobs' }}>
      {topJobs.map((job, index) => (
        <RecommendedJobCard
          key={`recommendedJob_${job.id}`}
          testAutomation={{
            jobCardLink: `recommendedJobLink_${job.id}`,
          }}
          jobInfo={job}
          position={index + 1}
        />
      ))}
    </CardsStack>
  );
};

export const TopRecommendations = () => {
  const { t } = useTranslations(translations);
  const { loading, rawRecommendedJobs } = useHomeRecommendations();
  useTrackingRecommendedJobs(rawRecommendedJobs);

  return (
    <Stack space="small">
      <Inline space="xxsmall" alignY="center">
        <Heading level="3">{t('Recommended jobs')}</Heading>
        <TooltipRenderer
          id=""
          tooltip={
            <Text align="center" size="small">
              {t('Based on your profile, job views and applications.')}
            </Text>
          }
        >
          {({ triggerProps }) => (
            <Box aria-label="Info" {...triggerProps}>
              <IconInfo size="xsmall" />
            </Box>
          )}
        </TooltipRenderer>
      </Inline>

      <AnimatedHeight changeKeys={[loading]}>
        <TopRecommendationsContent />
      </AnimatedHeight>
    </Stack>
  );
};
