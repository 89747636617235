import { useAppConfig } from 'src/config/appConfig';
import { useDispatch } from 'src/store/react';

import { getQuickSearchLinks } from '../utils/getQuickSearchLinks';

export const useQuickSearch = () => {
  const dispatch = useDispatch();
  const { zone, country, language: languageCode } = useAppConfig();

  const links = getQuickSearchLinks(zone, languageCode);

  return {
    dispatch,
    country,
    languageCode,
    zone,
    links,
  };
};
