import "src/components/HomePage/HomePageBanner/GenericAsiaBanner/GenericAsiaBanner.css.ts.vanilla.css!=!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.13_@types+node@22.5.4_babel-plugin-macros@3.1.0_terser@5.32.0_webpack@5.95.0/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/HomePage/HomePageBanner/GenericAsiaBanner/GenericAsiaBanner.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA+2SzXKCMBRG9z5FlrKIw4+CwKZv0olJhLSQ0BCFseO7d4zimCtIu+8O8p1k7neS1Xug/L4tTgx9LxDaV7zHTGhOjVAyQ0eilxgPzMbLF+fFavjldktDGBOywEY1kI+9/AHYKWNUDZnEYSq+N5DYOoQWRfmEpO5geztYycdQYk+rhcTj+c7mnWCmhBG10Y7Qz0Krg2RY1KTgkAog1YoTzxBVR65BonnDicmQVLdPkDeqFWP3ELp1C1v3YR9VldJwk+9uKucuL3J5YfmuFIbjtiGUX8buNGkc6sNSDy/mUrw61DJ3lkmGIt9veneVZiiM13A1uQ8WJl8yqHzPzeOZfDuTpyC/9n6rORMEtVRzLhGRDC1r0uPbu9hchvdsV1B9svxU/SkBrxREHiTGJcxreCXiSpwnZAg5yEjW/zLuMtI0/KuM+UOD8LfvTatuzG8ajfqN1vG833jW7zMB/T4T0G88+P0BnBjKvpEGAAA=\"}!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.13_@types+node@22.5.4_babel-plugin-macros@3.1.0_terser@5.32.0_webpack@5.95.0/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var background = '_1o0xsgzg';
export var backgroundColour = 'var(--_1o0xsgz0)';
export var backgroundImage = 'var(--_1o0xsgz1)';
export var backgroundPosition = 'var(--_1o0xsgz2)';
export var containerDirection = 'var(--_1o0xsgz5)';
export var containerLayout = '_1o0xsgzd';
export var contentGutter = '_1o0xsgze';
export var contentPaddingBottom = 'var(--_1o0xsgz7)';
export var contentPaddingLeft = 'var(--_1o0xsgz8)';
export var contentPaddingRight = 'var(--_1o0xsgz9)';
export var contentPaddingTop = 'var(--_1o0xsgz6)';
export var image = '_1o0xsgzf';
export var imageHeightVar = 'var(--_1o0xsgza)';
export var imageMinHeightVar = 'var(--_1o0xsgzb)';
export var imageWidthVar = 'var(--_1o0xsgzc)';
export var nowrap = '_1o0xsgzi';
export var responsive = '_1o0xsgzj';
export var title = '_1o0xsgzh';
export var titleFontSize = 'var(--_1o0xsgz4)';
export var titleTopPadding = 'var(--_1o0xsgz3)';