import { Box, Column, Columns, IconSearch } from 'braid-design-system';
import { memo, type ReactNode } from 'react';

// Workaround to left align the label of a Braid `Button`.
// Left-aligned labels are not supported and a bit abnormal,
// so working around it suit this use case, and can consider
// standardising support in the future if required.
interface LeftAlignedButtonLabelProps {
  label: string;
  icon?: ReactNode;
}

const LeftAlignedButtonLabel = ({
  label,
  icon,
}: LeftAlignedButtonLabelProps) => (
  <Box component="span" textAlign="left">
    <Columns component="span" space="xsmall">
      <Column width="content">{icon ? icon : <IconSearch />}</Column>
      <Column>{label}</Column>
    </Columns>
  </Box>
);

export default memo(LeftAlignedButtonLabel);
