
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGcmF1ZCBSZXBvcnQgU3VjY2VzcyI6IkZyYXVkIFJlcG9ydCBTdWNjZXNzIiwiRnJhdWQgUmVwb3J0IEZhaWx1cmUiOiJGcmF1ZCBSZXBvcnQgRmFpbHVyZSIsIlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLi4uIjoiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIFRyeSBhZ2FpbiBpbiBhIGxpdHRsZSB3aGlsZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGcmF1ZCBSZXBvcnQgU3VjY2VzcyI6IkZyYXVkIFJlcG9ydCBTdWNjZXNzIiwiRnJhdWQgUmVwb3J0IEZhaWx1cmUiOiJGcmF1ZCBSZXBvcnQgRmFpbHVyZSIsIlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLi4uIjoiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIFRyeSBhZ2FpbiBpbiBhIGxpdHRsZSB3aGlsZS4ifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGcmF1ZCBSZXBvcnQgU3VjY2VzcyI6IkZyYXVkIFJlcG9ydCBTdWNjZXNzIiwiRnJhdWQgUmVwb3J0IEZhaWx1cmUiOiJGcmF1ZCBSZXBvcnQgRmFpbHVyZSIsIlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLi4uIjoiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIFRyeSBhZ2FpbiBpbiBhIGxpdHRsZSB3aGlsZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGcmF1ZCBSZXBvcnQgU3VjY2VzcyI6IkZyYXVkIFJlcG9ydCBTdWNjZXNzIiwiRnJhdWQgUmVwb3J0IEZhaWx1cmUiOiJGcmF1ZCBSZXBvcnQgRmFpbHVyZSIsIlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLi4uIjoiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIFRyeSBhZ2FpbiBpbiBhIGxpdHRsZSB3aGlsZS4ifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGcmF1ZCBSZXBvcnQgU3VjY2VzcyI6IkZyYXVkIFJlcG9ydCBTdWNjZXNzIiwiRnJhdWQgUmVwb3J0IEZhaWx1cmUiOiJGcmF1ZCBSZXBvcnQgRmFpbHVyZSIsIlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLi4uIjoiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIFRyeSBhZ2FpbiBpbiBhIGxpdHRsZSB3aGlsZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGcmF1ZCBSZXBvcnQgU3VjY2VzcyI6IkZyYXVkIFJlcG9ydCBTdWNjZXNzIiwiRnJhdWQgUmVwb3J0IEZhaWx1cmUiOiJGcmF1ZCBSZXBvcnQgRmFpbHVyZSIsIlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLi4uIjoiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIFRyeSBhZ2FpbiBpbiBhIGxpdHRsZSB3aGlsZS4ifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGcmF1ZCBSZXBvcnQgU3VjY2VzcyI6IkZyYXVkIFJlcG9ydCBTdWNjZXNzIiwiRnJhdWQgUmVwb3J0IEZhaWx1cmUiOiJGcmF1ZCBSZXBvcnQgRmFpbHVyZSIsIlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLi4uIjoiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIFRyeSBhZ2FpbiBpbiBhIGxpdHRsZSB3aGlsZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGcmF1ZCBSZXBvcnQgU3VjY2VzcyI6IkZyYXVkIFJlcG9ydCBTdWNjZXNzIiwiRnJhdWQgUmVwb3J0IEZhaWx1cmUiOiJGcmF1ZCBSZXBvcnQgRmFpbHVyZSIsIlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLi4uIjoiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIFRyeSBhZ2FpbiBpbiBhIGxpdHRsZSB3aGlsZS4ifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGcmF1ZCBSZXBvcnQgU3VjY2VzcyI6IkZyYXVkIFJlcG9ydCBTdWNjZXNzIiwiRnJhdWQgUmVwb3J0IEZhaWx1cmUiOiJGcmF1ZCBSZXBvcnQgRmFpbHVyZSIsIlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLi4uIjoiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIFRyeSBhZ2FpbiBpbiBhIGxpdHRsZSB3aGlsZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGcmF1ZCBSZXBvcnQgU3VjY2VzcyI6IkZyYXVkIFJlcG9ydCBTdWNjZXNzIiwiRnJhdWQgUmVwb3J0IEZhaWx1cmUiOiJGcmF1ZCBSZXBvcnQgRmFpbHVyZSIsIlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLi4uIjoiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIFRyeSBhZ2FpbiBpbiBhIGxpdHRsZSB3aGlsZS4ifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGcmF1ZCBSZXBvcnQgU3VjY2VzcyI6IkZyYXVkIFJlcG9ydCBTdWNjZXNzIiwiRnJhdWQgUmVwb3J0IEZhaWx1cmUiOiJGcmF1ZCBSZXBvcnQgRmFpbHVyZSIsIlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLi4uIjoiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIFRyeSBhZ2FpbiBpbiBhIGxpdHRsZSB3aGlsZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGcmF1ZCBSZXBvcnQgU3VjY2VzcyI6IkZyYXVkIFJlcG9ydCBTdWNjZXNzIiwiRnJhdWQgUmVwb3J0IEZhaWx1cmUiOiJGcmF1ZCBSZXBvcnQgRmFpbHVyZSIsIlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLi4uIjoiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIFRyeSBhZ2FpbiBpbiBhIGxpdHRsZSB3aGlsZS4ifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGcmF1ZCBSZXBvcnQgU3VjY2VzcyI6IkZyYXVkIFJlcG9ydCBTdWNjZXNzIiwiRnJhdWQgUmVwb3J0IEZhaWx1cmUiOiJGcmF1ZCBSZXBvcnQgRmFpbHVyZSIsIlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLi4uIjoiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIFRyeSBhZ2FpbiBpbiBhIGxpdHRsZSB3aGlsZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGcmF1ZCBSZXBvcnQgU3VjY2VzcyI6IkZyYXVkIFJlcG9ydCBTdWNjZXNzIiwiRnJhdWQgUmVwb3J0IEZhaWx1cmUiOiJGcmF1ZCBSZXBvcnQgRmFpbHVyZSIsIlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLi4uIjoiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIFRyeSBhZ2FpbiBpbiBhIGxpdHRsZSB3aGlsZS4ifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGcmF1ZCBSZXBvcnQgU3VjY2VzcyI6IkZyYXVkIFJlcG9ydCBTdWNjZXNzIiwiRnJhdWQgUmVwb3J0IEZhaWx1cmUiOiJGcmF1ZCBSZXBvcnQgRmFpbHVyZSIsIlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLi4uIjoiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIFRyeSBhZ2FpbiBpbiBhIGxpdHRsZSB3aGlsZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGcmF1ZCBSZXBvcnQgU3VjY2VzcyI6IkZyYXVkIFJlcG9ydCBTdWNjZXNzIiwiRnJhdWQgUmVwb3J0IEZhaWx1cmUiOiJGcmF1ZCBSZXBvcnQgRmFpbHVyZSIsIlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLi4uIjoiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIFRyeSBhZ2FpbiBpbiBhIGxpdHRsZSB3aGlsZS4ifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGcmF1ZCBSZXBvcnQgU3VjY2VzcyI6IkZyYXVkIFJlcG9ydCBTdWNjZXNzIiwiRnJhdWQgUmVwb3J0IEZhaWx1cmUiOiJGcmF1ZCBSZXBvcnQgRmFpbHVyZSIsIlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLi4uIjoiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIFRyeSBhZ2FpbiBpbiBhIGxpdHRsZSB3aGlsZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGcmF1ZCBSZXBvcnQgU3VjY2VzcyI6IkZyYXVkIFJlcG9ydCBTdWNjZXNzIiwiRnJhdWQgUmVwb3J0IEZhaWx1cmUiOiJGcmF1ZCBSZXBvcnQgRmFpbHVyZSIsIlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLi4uIjoiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIFRyeSBhZ2FpbiBpbiBhIGxpdHRsZSB3aGlsZS4ifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGcmF1ZCBSZXBvcnQgU3VjY2VzcyI6IkZyYXVkIFJlcG9ydCBTdWNjZXNzIiwiRnJhdWQgUmVwb3J0IEZhaWx1cmUiOiJGcmF1ZCBSZXBvcnQgRmFpbHVyZSIsIlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLi4uIjoiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIFRyeSBhZ2FpbiBpbiBhIGxpdHRsZSB3aGlsZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGcmF1ZCBSZXBvcnQgU3VjY2VzcyI6IkZyYXVkIFJlcG9ydCBTdWNjZXNzIiwiRnJhdWQgUmVwb3J0IEZhaWx1cmUiOiJGcmF1ZCBSZXBvcnQgRmFpbHVyZSIsIlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLi4uIjoiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIFRyeSBhZ2FpbiBpbiBhIGxpdHRsZSB3aGlsZS4ifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGcmF1ZCBSZXBvcnQgU3VjY2VzcyI6IkZyYXVkIFJlcG9ydCBTdWNjZXNzIiwiRnJhdWQgUmVwb3J0IEZhaWx1cmUiOiJGcmF1ZCBSZXBvcnQgRmFpbHVyZSIsIlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLi4uIjoiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIFRyeSBhZ2FpbiBpbiBhIGxpdHRsZSB3aGlsZS4ifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGcmF1ZCBSZXBvcnQgU3VjY2VzcyI6IkZyYXVkIFJlcG9ydCBTdWNjZXNzIiwiRnJhdWQgUmVwb3J0IEZhaWx1cmUiOiJGcmF1ZCBSZXBvcnQgRmFpbHVyZSIsIlRoZXJlIGlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLi4uIjoiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIFRyeSBhZ2FpbiBpbiBhIGxpdHRsZSB3aGlsZS4ifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGcmF1ZCBSZXBvcnQgU3VjY2VzcyI6IkxhcG9yYW4gUGVuaXB1YW4gQmVyaGFzaWwiLCJGcmF1ZCBSZXBvcnQgRmFpbHVyZSI6IkxhcG9yYW4gUGVuaXB1YW4gR2FnYWwiLCJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6IlRlcmphZGkga2VzYWxhaGFuIGRhcmkgcGloYWsga2FtaS4gQ29iYSBsYWdpIG5hbnRpLiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGcmF1ZCBSZXBvcnQgU3VjY2VzcyI6IkxhcG9yYW4gUGVuaXB1YW4gQmVyaGFzaWwiLCJGcmF1ZCBSZXBvcnQgRmFpbHVyZSI6IkxhcG9yYW4gUGVuaXB1YW4gR2FnYWwiLCJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4uLiI6IlRlcmphZGkga2VzYWxhaGFuIGRhcmkgcGloYWsga2FtaS4gQ29iYSBsYWdpIG5hbnRpLiJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGcmF1ZCBSZXBvcnQgU3VjY2VzcyI6IuC4o+C4suC4ouC4h+C4suC4meC4geC4suC4o+C4ieC5ieC4reC5guC4geC4h+C5geC4peC5ieC4pyIsIkZyYXVkIFJlcG9ydCBGYWlsdXJlIjoi4Lij4Liy4Lii4LiH4Liy4LiZ4LiB4Liy4Lij4LiJ4LmJ4Lit4LmC4LiB4LiH4LmE4Lih4LmI4Liq4Liz4LmA4Lij4LmH4LiIIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuLi4iOiLguKHguLXguILguYnguK3guJzguLTguJTguJ7guKXguLLguJTguJfguLLguIfguJTguYnguLLguJnguITguLjguJMg4Lil4Lit4LiH4LmD4Lir4Lih4LmI4LmD4LiZ4Lit4Li14LiB4Liq4Lix4LiB4LiE4Lij4Li54LmIIn0=!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGcmF1ZCBSZXBvcnQgU3VjY2VzcyI6IuC4o+C4suC4ouC4h+C4suC4meC4geC4suC4o+C4ieC5ieC4reC5guC4geC4h+C5geC4peC5ieC4pyIsIkZyYXVkIFJlcG9ydCBGYWlsdXJlIjoi4Lij4Liy4Lii4LiH4Liy4LiZ4LiB4Liy4Lij4LiJ4LmJ4Lit4LmC4LiB4LiH4LmE4Lih4LmI4Liq4Liz4LmA4Lij4LmH4LiIIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuLi4iOiLguKHguLXguILguYnguK3guJzguLTguJTguJ7guKXguLLguJTguJfguLLguIfguJTguYnguLLguJnguITguLjguJMg4Lil4Lit4LiH4LmD4Lir4Lih4LmI4LmD4LiZ4Lit4Li14LiB4Liq4Lix4LiB4LiE4Lij4Li54LmIIn0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGcmF1ZCBSZXBvcnQgU3VjY2VzcyI6IlvGkcWZxIPEg8SDx5rHmseaxowgxZjhur3hur3hur3GpcO2w7bDtsWZ4bmvIMWgx5rHmseaw6fDp+G6veG6veG6vcWhxaFdIiwiRnJhdWQgUmVwb3J0IEZhaWx1cmUiOiJbxpHFmcSDxIPHmseaxowgxZjhur3hur3GpcO2w7bFmeG5ryDGkcSDxIPDrMOsxprHmseaxZnhur3hur1dIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuLi4iOiJb4bmu4bip4bq94bq94bq9xZnhur3hur3hur0gw6zDrMOsxaEgxaHDtsO2w7ZtzILhur3hur3hur3hua/huKnDrMOsw6zguIHguLXguYnEoyDFtcWZw7bDtsO24LiB4Li14LmJxKMgw7bDtsO24LiB4Li14LmJIMO2w7bDtseax5rHmsWZIOG6veG6veG6veC4geC4teC5icaMLiDhua7FmcO9w73DvSDEg8SDxIPEo8SDxIPEg8Osw6zDrOC4geC4teC5iSDDrMOsw6zguIHguLXguYkgxIPEg8SDIMaaw6zDrMOs4bmv4bmvxprhur3hur3hur0gxbXhuKnDrMOsw6zGmuG6veG6veG6vS5dIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGcmF1ZCBSZXBvcnQgU3VjY2VzcyI6IlvGkcWZxIPEg8SDx5rHmseaxowgxZjhur3hur3hur3GpcO2w7bDtsWZ4bmvIMWgx5rHmseaw6fDp+G6veG6veG6vcWhxaFdIiwiRnJhdWQgUmVwb3J0IEZhaWx1cmUiOiJbxpHFmcSDxIPHmseaxowgxZjhur3hur3GpcO2w7bFmeG5ryDGkcSDxIPDrMOsxprHmseaxZnhur3hur1dIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuLi4iOiJb4bmu4bip4bq94bq94bq9xZnhur3hur3hur0gw6zDrMOsxaEgxaHDtsO2w7ZtzILhur3hur3hur3hua/huKnDrMOsw6zguIHguLXguYnEoyDFtcWZw7bDtsO24LiB4Li14LmJxKMgw7bDtsO24LiB4Li14LmJIMO2w7bDtseax5rHmsWZIOG6veG6veG6veC4geC4teC5icaMLiDhua7FmcO9w73DvSDEg8SDxIPEo8SDxIPEg8Osw6zDrOC4geC4teC5iSDDrMOsw6zguIHguLXguYkgxIPEg8SDIMaaw6zDrMOs4bmv4bmvxprhur3hur3hur0gxbXhuKnDrMOsw6zGmuG6veG6veG6vS5dIn0=!"
        )
      )
      });
  
      export { translations as default };
    