import { useQuery } from '@apollo/client';
import type { ComponentProps } from 'react';

import { useAppConfig } from 'src/config/appConfig';
import {
  GetHomeSavedJobsDocument,
  type GetHomeSavedJobsQuery,
} from 'src/graphql/graphql';
import { useTimezone } from 'src/hooks/useTimezone';

import type JobCard from '../SharedComponent/JobCard/JobCard';

const mapSavedJobs = (
  edges?: NonNullable<
    NonNullable<
      NonNullable<GetHomeSavedJobsQuery['viewer']>['savedJobs']
    >['edges']
  >,
): Array<ComponentProps<typeof JobCard>['jobInfo']> =>
  edges?.map((edge) => {
    const {
      isActive,
      job: { advertiser, id, title, listedAt, location, salary, products },
    } = edge.node;

    return {
      advertiserName: advertiser.name,
      id,
      locationLabel: location.label,
      salaryLabel: salary?.label,
      salaryCurrencyLabel: salary?.currencyLabel ?? undefined,
      title,
      listingDateLabel: listedAt?.label,
      srcLogo: products?.branding?.logo?.url,
      isExpired: !isActive,
    };
  }) || [];

export const useSavedJobs = () => {
  const { locale, zone } = useAppConfig();
  const timezone = useTimezone();

  const { data, error, loading } = useQuery(GetHomeSavedJobsDocument, {
    variables: {
      locale,
      timezone,
      zone,
    },
    ssr: false,
  });

  return {
    jobs: mapSavedJobs(data?.viewer?.savedJobs.edges) ?? null,
    totalCount: data?.viewer?.savedJobs.total ?? 0,
    error,
    loading,
  };
};
