import { Box, Stack, Text } from 'braid-design-system';
import { type ComponentProps, memo, useCallback } from 'react';

import { LazyLoadedImg } from 'src/components/HomePage/LazyLoadedImg/LazyLoadedImg';
import NavLink from 'src/components/NavLink/NavLink';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import type { LoggedOutRecommendation } from 'src/modules/hooks/useLoggedOutRecommendations.ts';
import useSolMetadataReference from 'src/modules/hooks/useSolMetadataReference';
import SolImpressionTracker from 'src/modules/solTracker/SolImpressionTracker.tsx';
import { useLocalisedLinks } from 'src/utils/melwaysHelper';

import StandardCompanyLogo, {
  LOGO_HEIGHT,
} from '../components/StandardCompanyLogo/StandardCompanyLogo';

import * as styles from './LoggedOutRecsJobCard.css';

interface LoggedOutRecsJobCardProps
  extends Pick<ComponentProps<typeof Box>, 'aria-label'> {
  recs: LoggedOutRecommendation;
  position?: string;
}

const LoggedOutRecsJobCard = ({
  'aria-label': ariaLabel,
  recs,
  position,
}: LoggedOutRecsJobCardProps) => {
  const { job } = recs;
  const title = job.title;
  const companyName = job.advertiser.name;
  const locationLabel = job.location.label;
  const listingDate = job.listedAt;
  const srcLogo = job.products?.branding?.logo.url;
  const listingDateShortLabel = listingDate?.shortLabel ?? <>&nbsp;</>;

  const solMetadataReference = useSolMetadataReference({
    jobId: Number(job.id),
    displayType: 'loggedOutHomeRecs',
  });

  const jobUrl = useLocalisedLinks({
    path: `/job/${job.id}`,
  });
  const analyticsFacade = useAnalyticsFacade();

  const onJobCardClick = useCallback(() => {
    analyticsFacade.linkClicked({
      href: jobUrl,
      linkName: 'dashboard-recommended-job-click',
      linkContext: {
        jobId: job.id,
        linkPosition: position,
        linkSection: 'Recommended Jobs',
      },
    });
  }, [analyticsFacade, job.id, position, jobUrl]);

  return (
    <NavLink
      aria-label={ariaLabel}
      onClick={onJobCardClick}
      location={{
        pathname: jobUrl,
        query: {
          ref: 'recom-homepage',
        },
        hash: `sol=${solMetadataReference}`,
      }}
    >
      <Box
        borderRadius="large"
        className={[
          styles.jobCardBase,
          styles.hoveredJobCard,
          styles.focusedJobCard,
        ]}
        padding="medium"
        cursor="pointer"
        height="full"
        data={{
          automation: 'recsJobCard',
        }}
      >
        <Stack space="small">
          <Box
            style={{ height: LOGO_HEIGHT }}
            display="flex"
            alignItems="center"
          >
            {srcLogo ? (
              <LazyLoadedImg src={srcLogo} />
            ) : (
              <StandardCompanyLogo />
            )}
          </Box>
          <Text weight="strong" component="h3" maxLines={1}>
            {title}
          </Text>
          <Text tone="secondary" maxLines={1}>
            {companyName}
          </Text>
          <Text tone="secondary" maxLines={1}>
            {locationLabel}
          </Text>
          <Text size="small" tone="secondary" maxLines={1}>
            {listingDateShortLabel}
          </Text>
        </Stack>
      </Box>
    </NavLink>
  );
};
const withSolImpressionTracker = ({
  recs,
  'aria-label': ariaLabel,
  position,
}: LoggedOutRecsJobCardProps) => (
  <SolImpressionTracker
    jobId={recs.job.id}
    solMetadata={recs.solMetadata}
    wrapperBoxProps={{ height: 'full' }}
  >
    <LoggedOutRecsJobCard
      recs={recs}
      aria-label={ariaLabel}
      position={position}
    />
  </SolImpressionTracker>
);

export default memo(withSolImpressionTracker);
