import type { JobViewType } from '@seek/seek-jobs-analytics';
import { useCallback, useMemo } from 'react';

import { useAppConfig } from 'src/config/appConfig';
import {
  isBrowserAnalyticsFacade,
  useAnalyticsFacade,
} from 'src/modules/AnalyticsFacade';
import { useSelector } from 'src/store/react';
import { selectSessionId } from 'src/store/selectors';

import useSaveJobMutation from './utils/useSaveJobMutation';
import type { UseToggleSaveJobProps } from './utils/useToggleSavedJob';
interface UseSaveJobHomeRecsProps
  extends Pick<UseToggleSaveJobProps, 'jobId' | 'solMetadataReference'> {
  isSaved?: boolean;
  analytics: {
    jobViewOriginQuery: string;
    jobListingPosition: number;
    jobViewType: JobViewType;
    linkPosition: string;
  };
}

export const useSaveJobHomeRecs = ({
  jobId,
  solMetadataReference,
  isSaved,
  analytics,
}: UseSaveJobHomeRecsProps) => {
  const { zone, locale } = useAppConfig();
  const sessionId = useSelector(selectSessionId);
  const analyticsFacade = useAnalyticsFacade();
  const { jobViewOriginQuery, jobListingPosition, jobViewType, linkPosition } =
    analytics;

  const { create: createSavedJob, delete: deleteSavedJob } =
    useSaveJobMutation(jobId);

  const eventCaptureData = useMemo(
    () => ({
      channel: 'web',
      view: 'recommendationsList',
      eventCaptureSessionId: sessionId ?? '',
    }),
    [sessionId],
  );

  const commonInput = useMemo(
    () => ({
      id: `${jobId}`,
      eventCaptureData,
    }),
    [jobId, eventCaptureData],
  );

  const createSavedJobOptions = useMemo(
    () => ({
      variables: {
        input: {
          ...commonInput,
          zone,
        },
        locale,
      },
    }),
    [commonInput, locale, zone],
  );

  const deleteSavedJobOptions = useMemo(
    () => ({
      variables: {
        input: commonInput,
        locale,
      },
    }),
    [commonInput, locale],
  );

  const toggleSavedJob = useCallback(() => {
    if (isSaved) {
      deleteSavedJob(deleteSavedJobOptions);

      if (isBrowserAnalyticsFacade(analyticsFacade)) {
        analyticsFacade.saveJobRemoved(
          { solMetadataReference },
          {
            jobId,
            jobViewOriginQuery,
            jobListingPosition,
            jobViewType,
            linkPosition,
          },
        );
      }
    } else {
      createSavedJob(createSavedJobOptions);

      if (isBrowserAnalyticsFacade(analyticsFacade)) {
        analyticsFacade.saveJobCreated(
          { solMetadataReference },
          {
            jobId,
            jobViewOriginQuery,
            jobListingPosition,
            jobViewType,
            linkPosition,
          },
        );
      }
    }
  }, [
    isSaved,
    deleteSavedJob,
    deleteSavedJobOptions,
    analyticsFacade,
    solMetadataReference,
    jobId,
    createSavedJob,
    createSavedJobOptions,
    jobViewOriginQuery,
    jobListingPosition,
    jobViewType,
    linkPosition,
  ]);

  return { toggleSavedJob };
};
