import type { Zone } from '@seek/audience-zones';
import type { Language } from '@seek/melways-sites';

import { majorCitiesLinks } from './majorCities';
import { otherLinks } from './other';

export const getQuickSearchLinks = (
  zone: Zone,
  languageCode: Language = 'en',
) => ({
  majorCities: majorCitiesLinks[zone][languageCode]!,
  other: otherLinks[zone][languageCode]!,
});
