import { memo, type ComponentProps } from 'react';

import { useSaveJobHomeRecs } from 'src/hooks/useSaveJob/useSaveJobHomeRecs';
import useSolMetadataReference from 'src/modules/hooks/useSolMetadataReference';
import SolImpressionTracker from 'src/modules/solTracker/SolImpressionTracker';
import { useSelector } from 'src/store/react';
import { selectFeatureFlag } from 'src/store/selectors';

import JobCard from '../SharedComponent/JobCard/JobCard';
import { getRecommendationBadges } from '../SharedComponent/JobCard/hooks/getRecommendationBadges';

const RECOMMENDED_JOB_VIEW_ORIGIN = 'recom-homepage';

const JobCardWithTracking = (
  props: Omit<ComponentProps<typeof JobCard>, 'analytics'> & {
    position: number;
  },
) => {
  const solMetadataReference = useSolMetadataReference({
    jobId: Number(props.jobInfo.id),
    displayType: 'loggedInHomeRecs',
  });
  const showSaveJobButton = useSelector(selectFeatureFlag('homepageSaveJob'));

  const { toggleSavedJob } = useSaveJobHomeRecs({
    isSaved: props.jobInfo.isSaved,
    jobId: props.jobInfo.id,
    solMetadataReference,
    analytics: {
      jobViewOriginQuery: 'recom-homepage',
      jobListingPosition: props.position,
      jobViewType: 'job card',
      linkPosition: `${props.position}`,
    },
  });

  const onSaveJobButtonClick = showSaveJobButton ? toggleSavedJob : undefined;

  const { analyticsBadges } = getRecommendationBadges(props.jobInfo.tags);

  return (
    <SolImpressionTracker
      jobId={props.jobInfo.id}
      solMetadata={props.jobInfo.solMetadata}
      jobTags={analyticsBadges}
    >
      <JobCard
        {...props}
        analytics={{
          linkName: 'dashboard-recommended-job-click',
          linkSection: 'Recommended Jobs',
          viewOrigin: RECOMMENDED_JOB_VIEW_ORIGIN,
          position: props.position,
          solHash: solMetadataReference,
        }}
        onSaveJobButtonClick={onSaveJobButtonClick}
      />
    </SolImpressionTracker>
  );
};

export const RecommendedJobCard = memo(JobCardWithTracking);
