import { useTranslations } from '@vocab/react';
import { Box, Inline, Text } from 'braid-design-system';
import { useCallback } from 'react';

import { quickSearchOtherClicked } from 'src/store/search';

import translations from '../.vocab';
import type { useQuickSearch } from '../hooks/useQuickSearch';

import { QuickSearchLink } from './QuickSearchLink';

import * as styles from '../styles.css';

type OtherProps = Omit<ReturnType<typeof useQuickSearch>, 'links'> & {
  links: ReturnType<typeof useQuickSearch>['links']['other'];
};

export const Other = (props: OtherProps) => {
  const { t } = useTranslations(translations);
  const { dispatch, links: otherLinks } = props;

  const onQuickSearchOtherClick = useCallback(
    (keywords: string) => {
      dispatch(quickSearchOtherClicked(keywords));
    },
    [dispatch],
  );

  return otherLinks && otherLinks.length > 0 ? (
    <Box paddingTop="xsmall" display="flex">
      <Box className={styles.rowLeftColumn}>
        <Text size="small" component="h2">
          {t('Other')}
        </Text>
      </Box>
      <Box>
        <Inline space="small">
          {otherLinks.map(({ keywords, location, rel }) => {
            const slugifiedKeywords = keywords
              .replace(/\s+|\//g, '-')
              ?.toLowerCase();

            return (
              <QuickSearchLink
                key={slugifiedKeywords}
                linkProps={{
                  ['aria-label']: keywords,
                  ['data-automation']: `quick-search-${slugifiedKeywords}-link`,
                  analytics: `quick-search-${slugifiedKeywords}`,
                  location,
                  rel,
                  onClick: () => onQuickSearchOtherClick(keywords),
                }}
              >
                {keywords}
              </QuickSearchLink>
            );
          })}
        </Inline>
      </Box>
    </Box>
  ) : null;
};
