
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJRdWljayBzZWFyY2giOiJRdWljayBzZWFyY2giLCJDbGFzc2lmaWNhdGlvbnMiOiJDbGFzc2lmaWNhdGlvbnMiLCJWaWV3IGFsbCI6IlZpZXcgYWxsIiwiTWFqb3IgY2l0aWVzIjoiTWFqb3IgY2l0aWVzIiwiT3RoZXIiOiJPdGhlciIsIkdyYWR1YXRlIC8gRW50cnkgTGV2ZWwiOiJHcmFkdWF0ZSAvIEVudHJ5IExldmVsIiwiVm9sdW50ZWVyIjoiVm9sdW50ZWVyIiwiU2VhcmNoIGJ5IHtrZXl3b3Jkc30iOiJ7a2V5d29yZHN9IEpvYnMiLCJTZWFyY2ggYnkgUmVjcnVpdGVyIjoiU2VhcmNoIGJ5IFJlY3J1aXRlciIsIlNlYXJjaCBieSBFbXBsb3llcnMiOiJTZWFyY2ggYnkgRW1wbG95ZXJzIiwiQ292aWQgMTkiOiJDb3ZpZCAxOSIsIkFsbCBKb2JzIjoiQWxsIEpvYnMiLCJKb2JzIGluIHtkZXNjcmlwdGlvbn0iOiJKb2JzIGluIHtkZXNjcmlwdGlvbn0iLCJKb2JzIGluIHtuYW1lfSI6IkpvYnMgaW4ge25hbWV9IiwiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIjoiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIiwiU2VhcmNoIGJ5IHJlY3J1aXRlciI6IlNlYXJjaCBieSByZWNydWl0ZXIifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJRdWljayBzZWFyY2giOiJRdWljayBzZWFyY2giLCJDbGFzc2lmaWNhdGlvbnMiOiJDbGFzc2lmaWNhdGlvbnMiLCJWaWV3IGFsbCI6IlZpZXcgYWxsIiwiTWFqb3IgY2l0aWVzIjoiTWFqb3IgY2l0aWVzIiwiT3RoZXIiOiJPdGhlciIsIkdyYWR1YXRlIC8gRW50cnkgTGV2ZWwiOiJHcmFkdWF0ZSAvIEVudHJ5IExldmVsIiwiVm9sdW50ZWVyIjoiVm9sdW50ZWVyIiwiU2VhcmNoIGJ5IHtrZXl3b3Jkc30iOiJ7a2V5d29yZHN9IEpvYnMiLCJTZWFyY2ggYnkgUmVjcnVpdGVyIjoiU2VhcmNoIGJ5IFJlY3J1aXRlciIsIlNlYXJjaCBieSBFbXBsb3llcnMiOiJTZWFyY2ggYnkgRW1wbG95ZXJzIiwiQ292aWQgMTkiOiJDb3ZpZCAxOSIsIkFsbCBKb2JzIjoiQWxsIEpvYnMiLCJKb2JzIGluIHtkZXNjcmlwdGlvbn0iOiJKb2JzIGluIHtkZXNjcmlwdGlvbn0iLCJKb2JzIGluIHtuYW1lfSI6IkpvYnMgaW4ge25hbWV9IiwiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIjoiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIiwiU2VhcmNoIGJ5IHJlY3J1aXRlciI6IlNlYXJjaCBieSByZWNydWl0ZXIifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJRdWljayBzZWFyY2giOiJRdWljayBzZWFyY2giLCJDbGFzc2lmaWNhdGlvbnMiOiJDbGFzc2lmaWNhdGlvbnMiLCJWaWV3IGFsbCI6IlZpZXcgYWxsIiwiTWFqb3IgY2l0aWVzIjoiTWFqb3IgY2l0aWVzIiwiT3RoZXIiOiJPdGhlciIsIkdyYWR1YXRlIC8gRW50cnkgTGV2ZWwiOiJHcmFkdWF0ZSAvIEVudHJ5IExldmVsIiwiVm9sdW50ZWVyIjoiVm9sdW50ZWVyIiwiU2VhcmNoIGJ5IHtrZXl3b3Jkc30iOiJ7a2V5d29yZHN9IEpvYnMiLCJTZWFyY2ggYnkgUmVjcnVpdGVyIjoiU2VhcmNoIGJ5IFJlY3J1aXRlciIsIlNlYXJjaCBieSBFbXBsb3llcnMiOiJTZWFyY2ggYnkgRW1wbG95ZXJzIiwiQ292aWQgMTkiOiJDb3ZpZCAxOSIsIkFsbCBKb2JzIjoiQWxsIEpvYnMiLCJKb2JzIGluIHtkZXNjcmlwdGlvbn0iOiJKb2JzIGluIHtkZXNjcmlwdGlvbn0iLCJKb2JzIGluIHtuYW1lfSI6IkpvYnMgaW4ge25hbWV9IiwiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIjoiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIiwiU2VhcmNoIGJ5IHJlY3J1aXRlciI6IlNlYXJjaCBieSByZWNydWl0ZXIifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJRdWljayBzZWFyY2giOiJRdWljayBzZWFyY2giLCJDbGFzc2lmaWNhdGlvbnMiOiJDbGFzc2lmaWNhdGlvbnMiLCJWaWV3IGFsbCI6IlZpZXcgYWxsIiwiTWFqb3IgY2l0aWVzIjoiTWFqb3IgY2l0aWVzIiwiT3RoZXIiOiJPdGhlciIsIkdyYWR1YXRlIC8gRW50cnkgTGV2ZWwiOiJHcmFkdWF0ZSAvIEVudHJ5IExldmVsIiwiVm9sdW50ZWVyIjoiVm9sdW50ZWVyIiwiU2VhcmNoIGJ5IHtrZXl3b3Jkc30iOiJ7a2V5d29yZHN9IEpvYnMiLCJTZWFyY2ggYnkgUmVjcnVpdGVyIjoiU2VhcmNoIGJ5IFJlY3J1aXRlciIsIlNlYXJjaCBieSBFbXBsb3llcnMiOiJTZWFyY2ggYnkgRW1wbG95ZXJzIiwiQ292aWQgMTkiOiJDb3ZpZCAxOSIsIkFsbCBKb2JzIjoiQWxsIEpvYnMiLCJKb2JzIGluIHtkZXNjcmlwdGlvbn0iOiJKb2JzIGluIHtkZXNjcmlwdGlvbn0iLCJKb2JzIGluIHtuYW1lfSI6IkpvYnMgaW4ge25hbWV9IiwiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIjoiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIiwiU2VhcmNoIGJ5IHJlY3J1aXRlciI6IlNlYXJjaCBieSByZWNydWl0ZXIifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJRdWljayBzZWFyY2giOiJRdWljayBzZWFyY2giLCJDbGFzc2lmaWNhdGlvbnMiOiJDbGFzc2lmaWNhdGlvbnMiLCJWaWV3IGFsbCI6IlZpZXcgYWxsIiwiTWFqb3IgY2l0aWVzIjoiTWFqb3IgY2l0aWVzIiwiT3RoZXIiOiJPdGhlciIsIkdyYWR1YXRlIC8gRW50cnkgTGV2ZWwiOiJHcmFkdWF0ZSAvIEVudHJ5IExldmVsIiwiVm9sdW50ZWVyIjoiVm9sdW50ZWVyIiwiU2VhcmNoIGJ5IHtrZXl3b3Jkc30iOiJ7a2V5d29yZHN9IEpvYnMiLCJTZWFyY2ggYnkgUmVjcnVpdGVyIjoiU2VhcmNoIGJ5IFJlY3J1aXRlciIsIlNlYXJjaCBieSBFbXBsb3llcnMiOiJTZWFyY2ggYnkgRW1wbG95ZXJzIiwiQ292aWQgMTkiOiJDb3ZpZCAxOSIsIkFsbCBKb2JzIjoiQWxsIEpvYnMiLCJKb2JzIGluIHtkZXNjcmlwdGlvbn0iOiJKb2JzIGluIHtkZXNjcmlwdGlvbn0iLCJKb2JzIGluIHtuYW1lfSI6IkpvYnMgaW4ge25hbWV9IiwiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIjoiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIiwiU2VhcmNoIGJ5IHJlY3J1aXRlciI6IlNlYXJjaCBieSByZWNydWl0ZXIifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJRdWljayBzZWFyY2giOiJRdWljayBzZWFyY2giLCJDbGFzc2lmaWNhdGlvbnMiOiJDbGFzc2lmaWNhdGlvbnMiLCJWaWV3IGFsbCI6IlZpZXcgYWxsIiwiTWFqb3IgY2l0aWVzIjoiTWFqb3IgY2l0aWVzIiwiT3RoZXIiOiJPdGhlciIsIkdyYWR1YXRlIC8gRW50cnkgTGV2ZWwiOiJHcmFkdWF0ZSAvIEVudHJ5IExldmVsIiwiVm9sdW50ZWVyIjoiVm9sdW50ZWVyIiwiU2VhcmNoIGJ5IHtrZXl3b3Jkc30iOiJ7a2V5d29yZHN9IEpvYnMiLCJTZWFyY2ggYnkgUmVjcnVpdGVyIjoiU2VhcmNoIGJ5IFJlY3J1aXRlciIsIlNlYXJjaCBieSBFbXBsb3llcnMiOiJTZWFyY2ggYnkgRW1wbG95ZXJzIiwiQ292aWQgMTkiOiJDb3ZpZCAxOSIsIkFsbCBKb2JzIjoiQWxsIEpvYnMiLCJKb2JzIGluIHtkZXNjcmlwdGlvbn0iOiJKb2JzIGluIHtkZXNjcmlwdGlvbn0iLCJKb2JzIGluIHtuYW1lfSI6IkpvYnMgaW4ge25hbWV9IiwiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIjoiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIiwiU2VhcmNoIGJ5IHJlY3J1aXRlciI6IlNlYXJjaCBieSByZWNydWl0ZXIifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJRdWljayBzZWFyY2giOiJRdWljayBzZWFyY2giLCJDbGFzc2lmaWNhdGlvbnMiOiJDbGFzc2lmaWNhdGlvbnMiLCJWaWV3IGFsbCI6IlZpZXcgYWxsIiwiTWFqb3IgY2l0aWVzIjoiTWFqb3IgY2l0aWVzIiwiT3RoZXIiOiJPdGhlciIsIkdyYWR1YXRlIC8gRW50cnkgTGV2ZWwiOiJHcmFkdWF0ZSAvIEVudHJ5IExldmVsIiwiVm9sdW50ZWVyIjoiVm9sdW50ZWVyIiwiU2VhcmNoIGJ5IHtrZXl3b3Jkc30iOiJ7a2V5d29yZHN9IEpvYnMiLCJTZWFyY2ggYnkgUmVjcnVpdGVyIjoiU2VhcmNoIGJ5IFJlY3J1aXRlciIsIlNlYXJjaCBieSBFbXBsb3llcnMiOiJTZWFyY2ggYnkgRW1wbG95ZXJzIiwiQ292aWQgMTkiOiJDb3ZpZCAxOSIsIkFsbCBKb2JzIjoiQWxsIEpvYnMiLCJKb2JzIGluIHtkZXNjcmlwdGlvbn0iOiJKb2JzIGluIHtkZXNjcmlwdGlvbn0iLCJKb2JzIGluIHtuYW1lfSI6IkpvYnMgaW4ge25hbWV9IiwiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIjoiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIiwiU2VhcmNoIGJ5IHJlY3J1aXRlciI6IlNlYXJjaCBieSByZWNydWl0ZXIifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJRdWljayBzZWFyY2giOiJRdWljayBzZWFyY2giLCJDbGFzc2lmaWNhdGlvbnMiOiJDbGFzc2lmaWNhdGlvbnMiLCJWaWV3IGFsbCI6IlZpZXcgYWxsIiwiTWFqb3IgY2l0aWVzIjoiTWFqb3IgY2l0aWVzIiwiT3RoZXIiOiJPdGhlciIsIkdyYWR1YXRlIC8gRW50cnkgTGV2ZWwiOiJHcmFkdWF0ZSAvIEVudHJ5IExldmVsIiwiVm9sdW50ZWVyIjoiVm9sdW50ZWVyIiwiU2VhcmNoIGJ5IHtrZXl3b3Jkc30iOiJ7a2V5d29yZHN9IEpvYnMiLCJTZWFyY2ggYnkgUmVjcnVpdGVyIjoiU2VhcmNoIGJ5IFJlY3J1aXRlciIsIlNlYXJjaCBieSBFbXBsb3llcnMiOiJTZWFyY2ggYnkgRW1wbG95ZXJzIiwiQ292aWQgMTkiOiJDb3ZpZCAxOSIsIkFsbCBKb2JzIjoiQWxsIEpvYnMiLCJKb2JzIGluIHtkZXNjcmlwdGlvbn0iOiJKb2JzIGluIHtkZXNjcmlwdGlvbn0iLCJKb2JzIGluIHtuYW1lfSI6IkpvYnMgaW4ge25hbWV9IiwiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIjoiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIiwiU2VhcmNoIGJ5IHJlY3J1aXRlciI6IlNlYXJjaCBieSByZWNydWl0ZXIifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJRdWljayBzZWFyY2giOiJRdWljayBzZWFyY2giLCJDbGFzc2lmaWNhdGlvbnMiOiJDbGFzc2lmaWNhdGlvbnMiLCJWaWV3IGFsbCI6IlZpZXcgYWxsIiwiTWFqb3IgY2l0aWVzIjoiTWFqb3IgY2l0aWVzIiwiT3RoZXIiOiJPdGhlciIsIkdyYWR1YXRlIC8gRW50cnkgTGV2ZWwiOiJHcmFkdWF0ZSAvIEVudHJ5IExldmVsIiwiVm9sdW50ZWVyIjoiVm9sdW50ZWVyIiwiU2VhcmNoIGJ5IHtrZXl3b3Jkc30iOiJ7a2V5d29yZHN9IEpvYnMiLCJTZWFyY2ggYnkgUmVjcnVpdGVyIjoiU2VhcmNoIGJ5IFJlY3J1aXRlciIsIlNlYXJjaCBieSBFbXBsb3llcnMiOiJTZWFyY2ggYnkgRW1wbG95ZXJzIiwiQ292aWQgMTkiOiJDb3ZpZCAxOSIsIkFsbCBKb2JzIjoiQWxsIEpvYnMiLCJKb2JzIGluIHtkZXNjcmlwdGlvbn0iOiJKb2JzIGluIHtkZXNjcmlwdGlvbn0iLCJKb2JzIGluIHtuYW1lfSI6IkpvYnMgaW4ge25hbWV9IiwiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIjoiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIiwiU2VhcmNoIGJ5IHJlY3J1aXRlciI6IlNlYXJjaCBieSByZWNydWl0ZXIifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJRdWljayBzZWFyY2giOiJRdWljayBzZWFyY2giLCJDbGFzc2lmaWNhdGlvbnMiOiJDbGFzc2lmaWNhdGlvbnMiLCJWaWV3IGFsbCI6IlZpZXcgYWxsIiwiTWFqb3IgY2l0aWVzIjoiTWFqb3IgY2l0aWVzIiwiT3RoZXIiOiJPdGhlciIsIkdyYWR1YXRlIC8gRW50cnkgTGV2ZWwiOiJHcmFkdWF0ZSAvIEVudHJ5IExldmVsIiwiVm9sdW50ZWVyIjoiVm9sdW50ZWVyIiwiU2VhcmNoIGJ5IHtrZXl3b3Jkc30iOiJ7a2V5d29yZHN9IEpvYnMiLCJTZWFyY2ggYnkgUmVjcnVpdGVyIjoiU2VhcmNoIGJ5IFJlY3J1aXRlciIsIlNlYXJjaCBieSBFbXBsb3llcnMiOiJTZWFyY2ggYnkgRW1wbG95ZXJzIiwiQ292aWQgMTkiOiJDb3ZpZCAxOSIsIkFsbCBKb2JzIjoiQWxsIEpvYnMiLCJKb2JzIGluIHtkZXNjcmlwdGlvbn0iOiJKb2JzIGluIHtkZXNjcmlwdGlvbn0iLCJKb2JzIGluIHtuYW1lfSI6IkpvYnMgaW4ge25hbWV9IiwiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIjoiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIiwiU2VhcmNoIGJ5IHJlY3J1aXRlciI6IlNlYXJjaCBieSByZWNydWl0ZXIifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJRdWljayBzZWFyY2giOiJRdWljayBzZWFyY2giLCJDbGFzc2lmaWNhdGlvbnMiOiJDbGFzc2lmaWNhdGlvbnMiLCJWaWV3IGFsbCI6IlZpZXcgYWxsIiwiTWFqb3IgY2l0aWVzIjoiTWFqb3IgY2l0aWVzIiwiT3RoZXIiOiJPdGhlciIsIkdyYWR1YXRlIC8gRW50cnkgTGV2ZWwiOiJHcmFkdWF0ZSAvIEVudHJ5IExldmVsIiwiVm9sdW50ZWVyIjoiVm9sdW50ZWVyIiwiU2VhcmNoIGJ5IHtrZXl3b3Jkc30iOiJ7a2V5d29yZHN9IEpvYnMiLCJTZWFyY2ggYnkgUmVjcnVpdGVyIjoiU2VhcmNoIGJ5IFJlY3J1aXRlciIsIlNlYXJjaCBieSBFbXBsb3llcnMiOiJTZWFyY2ggYnkgRW1wbG95ZXJzIiwiQ292aWQgMTkiOiJDb3ZpZCAxOSIsIkFsbCBKb2JzIjoiQWxsIEpvYnMiLCJKb2JzIGluIHtkZXNjcmlwdGlvbn0iOiJKb2JzIGluIHtkZXNjcmlwdGlvbn0iLCJKb2JzIGluIHtuYW1lfSI6IkpvYnMgaW4ge25hbWV9IiwiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIjoiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIiwiU2VhcmNoIGJ5IHJlY3J1aXRlciI6IlNlYXJjaCBieSByZWNydWl0ZXIifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJRdWljayBzZWFyY2giOiJRdWljayBzZWFyY2giLCJDbGFzc2lmaWNhdGlvbnMiOiJDbGFzc2lmaWNhdGlvbnMiLCJWaWV3IGFsbCI6IlZpZXcgYWxsIiwiTWFqb3IgY2l0aWVzIjoiTWFqb3IgY2l0aWVzIiwiT3RoZXIiOiJPdGhlciIsIkdyYWR1YXRlIC8gRW50cnkgTGV2ZWwiOiJHcmFkdWF0ZSAvIEVudHJ5IExldmVsIiwiVm9sdW50ZWVyIjoiVm9sdW50ZWVyIiwiU2VhcmNoIGJ5IHtrZXl3b3Jkc30iOiJ7a2V5d29yZHN9IEpvYnMiLCJTZWFyY2ggYnkgUmVjcnVpdGVyIjoiU2VhcmNoIGJ5IFJlY3J1aXRlciIsIlNlYXJjaCBieSBFbXBsb3llcnMiOiJTZWFyY2ggYnkgRW1wbG95ZXJzIiwiQ292aWQgMTkiOiJDb3ZpZCAxOSIsIkFsbCBKb2JzIjoiQWxsIEpvYnMiLCJKb2JzIGluIHtkZXNjcmlwdGlvbn0iOiJKb2JzIGluIHtkZXNjcmlwdGlvbn0iLCJKb2JzIGluIHtuYW1lfSI6IkpvYnMgaW4ge25hbWV9IiwiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIjoiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIiwiU2VhcmNoIGJ5IHJlY3J1aXRlciI6IlNlYXJjaCBieSByZWNydWl0ZXIifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJRdWljayBzZWFyY2giOiJRdWljayBzZWFyY2giLCJDbGFzc2lmaWNhdGlvbnMiOiJDbGFzc2lmaWNhdGlvbnMiLCJWaWV3IGFsbCI6IlZpZXcgYWxsIiwiTWFqb3IgY2l0aWVzIjoiTWFqb3IgY2l0aWVzIiwiT3RoZXIiOiJPdGhlciIsIkdyYWR1YXRlIC8gRW50cnkgTGV2ZWwiOiJHcmFkdWF0ZSAvIEVudHJ5IExldmVsIiwiVm9sdW50ZWVyIjoiVm9sdW50ZWVyIiwiU2VhcmNoIGJ5IHtrZXl3b3Jkc30iOiJ7a2V5d29yZHN9IEpvYnMiLCJTZWFyY2ggYnkgUmVjcnVpdGVyIjoiU2VhcmNoIGJ5IFJlY3J1aXRlciIsIlNlYXJjaCBieSBFbXBsb3llcnMiOiJTZWFyY2ggYnkgRW1wbG95ZXJzIiwiQ292aWQgMTkiOiJDb3ZpZCAxOSIsIkFsbCBKb2JzIjoiQWxsIEpvYnMiLCJKb2JzIGluIHtkZXNjcmlwdGlvbn0iOiJKb2JzIGluIHtkZXNjcmlwdGlvbn0iLCJKb2JzIGluIHtuYW1lfSI6IkpvYnMgaW4ge25hbWV9IiwiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIjoiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIiwiU2VhcmNoIGJ5IHJlY3J1aXRlciI6IlNlYXJjaCBieSByZWNydWl0ZXIifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJRdWljayBzZWFyY2giOiJRdWljayBzZWFyY2giLCJDbGFzc2lmaWNhdGlvbnMiOiJDbGFzc2lmaWNhdGlvbnMiLCJWaWV3IGFsbCI6IlZpZXcgYWxsIiwiTWFqb3IgY2l0aWVzIjoiTWFqb3IgY2l0aWVzIiwiT3RoZXIiOiJPdGhlciIsIkdyYWR1YXRlIC8gRW50cnkgTGV2ZWwiOiJHcmFkdWF0ZSAvIEVudHJ5IExldmVsIiwiVm9sdW50ZWVyIjoiVm9sdW50ZWVyIiwiU2VhcmNoIGJ5IHtrZXl3b3Jkc30iOiJ7a2V5d29yZHN9IEpvYnMiLCJTZWFyY2ggYnkgUmVjcnVpdGVyIjoiU2VhcmNoIGJ5IFJlY3J1aXRlciIsIlNlYXJjaCBieSBFbXBsb3llcnMiOiJTZWFyY2ggYnkgRW1wbG95ZXJzIiwiQ292aWQgMTkiOiJDb3ZpZCAxOSIsIkFsbCBKb2JzIjoiQWxsIEpvYnMiLCJKb2JzIGluIHtkZXNjcmlwdGlvbn0iOiJKb2JzIGluIHtkZXNjcmlwdGlvbn0iLCJKb2JzIGluIHtuYW1lfSI6IkpvYnMgaW4ge25hbWV9IiwiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIjoiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIiwiU2VhcmNoIGJ5IHJlY3J1aXRlciI6IlNlYXJjaCBieSByZWNydWl0ZXIifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJRdWljayBzZWFyY2giOiJRdWljayBzZWFyY2giLCJDbGFzc2lmaWNhdGlvbnMiOiJDbGFzc2lmaWNhdGlvbnMiLCJWaWV3IGFsbCI6IlZpZXcgYWxsIiwiTWFqb3IgY2l0aWVzIjoiTWFqb3IgY2l0aWVzIiwiT3RoZXIiOiJPdGhlciIsIkdyYWR1YXRlIC8gRW50cnkgTGV2ZWwiOiJHcmFkdWF0ZSAvIEVudHJ5IExldmVsIiwiVm9sdW50ZWVyIjoiVm9sdW50ZWVyIiwiU2VhcmNoIGJ5IHtrZXl3b3Jkc30iOiJ7a2V5d29yZHN9IEpvYnMiLCJTZWFyY2ggYnkgUmVjcnVpdGVyIjoiU2VhcmNoIGJ5IFJlY3J1aXRlciIsIlNlYXJjaCBieSBFbXBsb3llcnMiOiJTZWFyY2ggYnkgRW1wbG95ZXJzIiwiQ292aWQgMTkiOiJDb3ZpZCAxOSIsIkFsbCBKb2JzIjoiQWxsIEpvYnMiLCJKb2JzIGluIHtkZXNjcmlwdGlvbn0iOiJKb2JzIGluIHtkZXNjcmlwdGlvbn0iLCJKb2JzIGluIHtuYW1lfSI6IkpvYnMgaW4ge25hbWV9IiwiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIjoiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIiwiU2VhcmNoIGJ5IHJlY3J1aXRlciI6IlNlYXJjaCBieSByZWNydWl0ZXIifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJRdWljayBzZWFyY2giOiJRdWljayBzZWFyY2giLCJDbGFzc2lmaWNhdGlvbnMiOiJDbGFzc2lmaWNhdGlvbnMiLCJWaWV3IGFsbCI6IlZpZXcgYWxsIiwiTWFqb3IgY2l0aWVzIjoiTWFqb3IgY2l0aWVzIiwiT3RoZXIiOiJPdGhlciIsIkdyYWR1YXRlIC8gRW50cnkgTGV2ZWwiOiJHcmFkdWF0ZSAvIEVudHJ5IExldmVsIiwiVm9sdW50ZWVyIjoiVm9sdW50ZWVyIiwiU2VhcmNoIGJ5IHtrZXl3b3Jkc30iOiJ7a2V5d29yZHN9IEpvYnMiLCJTZWFyY2ggYnkgUmVjcnVpdGVyIjoiU2VhcmNoIGJ5IFJlY3J1aXRlciIsIlNlYXJjaCBieSBFbXBsb3llcnMiOiJTZWFyY2ggYnkgRW1wbG95ZXJzIiwiQ292aWQgMTkiOiJDb3ZpZCAxOSIsIkFsbCBKb2JzIjoiQWxsIEpvYnMiLCJKb2JzIGluIHtkZXNjcmlwdGlvbn0iOiJKb2JzIGluIHtkZXNjcmlwdGlvbn0iLCJKb2JzIGluIHtuYW1lfSI6IkpvYnMgaW4ge25hbWV9IiwiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIjoiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIiwiU2VhcmNoIGJ5IHJlY3J1aXRlciI6IlNlYXJjaCBieSByZWNydWl0ZXIifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJRdWljayBzZWFyY2giOiJRdWljayBzZWFyY2giLCJDbGFzc2lmaWNhdGlvbnMiOiJDbGFzc2lmaWNhdGlvbnMiLCJWaWV3IGFsbCI6IlZpZXcgYWxsIiwiTWFqb3IgY2l0aWVzIjoiTWFqb3IgY2l0aWVzIiwiT3RoZXIiOiJPdGhlciIsIkdyYWR1YXRlIC8gRW50cnkgTGV2ZWwiOiJHcmFkdWF0ZSAvIEVudHJ5IExldmVsIiwiVm9sdW50ZWVyIjoiVm9sdW50ZWVyIiwiU2VhcmNoIGJ5IHtrZXl3b3Jkc30iOiJ7a2V5d29yZHN9IEpvYnMiLCJTZWFyY2ggYnkgUmVjcnVpdGVyIjoiU2VhcmNoIGJ5IFJlY3J1aXRlciIsIlNlYXJjaCBieSBFbXBsb3llcnMiOiJTZWFyY2ggYnkgRW1wbG95ZXJzIiwiQ292aWQgMTkiOiJDb3ZpZCAxOSIsIkFsbCBKb2JzIjoiQWxsIEpvYnMiLCJKb2JzIGluIHtkZXNjcmlwdGlvbn0iOiJKb2JzIGluIHtkZXNjcmlwdGlvbn0iLCJKb2JzIGluIHtuYW1lfSI6IkpvYnMgaW4ge25hbWV9IiwiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIjoiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIiwiU2VhcmNoIGJ5IHJlY3J1aXRlciI6IlNlYXJjaCBieSByZWNydWl0ZXIifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJRdWljayBzZWFyY2giOiJRdWljayBzZWFyY2giLCJDbGFzc2lmaWNhdGlvbnMiOiJDbGFzc2lmaWNhdGlvbnMiLCJWaWV3IGFsbCI6IlZpZXcgYWxsIiwiTWFqb3IgY2l0aWVzIjoiTWFqb3IgY2l0aWVzIiwiT3RoZXIiOiJPdGhlciIsIkdyYWR1YXRlIC8gRW50cnkgTGV2ZWwiOiJHcmFkdWF0ZSAvIEVudHJ5IExldmVsIiwiVm9sdW50ZWVyIjoiVm9sdW50ZWVyIiwiU2VhcmNoIGJ5IHtrZXl3b3Jkc30iOiJ7a2V5d29yZHN9IEpvYnMiLCJTZWFyY2ggYnkgUmVjcnVpdGVyIjoiU2VhcmNoIGJ5IFJlY3J1aXRlciIsIlNlYXJjaCBieSBFbXBsb3llcnMiOiJTZWFyY2ggYnkgRW1wbG95ZXJzIiwiQ292aWQgMTkiOiJDb3ZpZCAxOSIsIkFsbCBKb2JzIjoiQWxsIEpvYnMiLCJKb2JzIGluIHtkZXNjcmlwdGlvbn0iOiJKb2JzIGluIHtkZXNjcmlwdGlvbn0iLCJKb2JzIGluIHtuYW1lfSI6IkpvYnMgaW4ge25hbWV9IiwiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIjoiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIiwiU2VhcmNoIGJ5IHJlY3J1aXRlciI6IlNlYXJjaCBieSByZWNydWl0ZXIifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJRdWljayBzZWFyY2giOiJRdWljayBzZWFyY2giLCJDbGFzc2lmaWNhdGlvbnMiOiJDbGFzc2lmaWNhdGlvbnMiLCJWaWV3IGFsbCI6IlZpZXcgYWxsIiwiTWFqb3IgY2l0aWVzIjoiTWFqb3IgY2l0aWVzIiwiT3RoZXIiOiJPdGhlciIsIkdyYWR1YXRlIC8gRW50cnkgTGV2ZWwiOiJHcmFkdWF0ZSAvIEVudHJ5IExldmVsIiwiVm9sdW50ZWVyIjoiVm9sdW50ZWVyIiwiU2VhcmNoIGJ5IHtrZXl3b3Jkc30iOiJ7a2V5d29yZHN9IEpvYnMiLCJTZWFyY2ggYnkgUmVjcnVpdGVyIjoiU2VhcmNoIGJ5IFJlY3J1aXRlciIsIlNlYXJjaCBieSBFbXBsb3llcnMiOiJTZWFyY2ggYnkgRW1wbG95ZXJzIiwiQ292aWQgMTkiOiJDb3ZpZCAxOSIsIkFsbCBKb2JzIjoiQWxsIEpvYnMiLCJKb2JzIGluIHtkZXNjcmlwdGlvbn0iOiJKb2JzIGluIHtkZXNjcmlwdGlvbn0iLCJKb2JzIGluIHtuYW1lfSI6IkpvYnMgaW4ge25hbWV9IiwiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIjoiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIiwiU2VhcmNoIGJ5IHJlY3J1aXRlciI6IlNlYXJjaCBieSByZWNydWl0ZXIifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJRdWljayBzZWFyY2giOiJRdWljayBzZWFyY2giLCJDbGFzc2lmaWNhdGlvbnMiOiJDbGFzc2lmaWNhdGlvbnMiLCJWaWV3IGFsbCI6IlZpZXcgYWxsIiwiTWFqb3IgY2l0aWVzIjoiTWFqb3IgY2l0aWVzIiwiT3RoZXIiOiJPdGhlciIsIkdyYWR1YXRlIC8gRW50cnkgTGV2ZWwiOiJHcmFkdWF0ZSAvIEVudHJ5IExldmVsIiwiVm9sdW50ZWVyIjoiVm9sdW50ZWVyIiwiU2VhcmNoIGJ5IHtrZXl3b3Jkc30iOiJ7a2V5d29yZHN9IEpvYnMiLCJTZWFyY2ggYnkgUmVjcnVpdGVyIjoiU2VhcmNoIGJ5IFJlY3J1aXRlciIsIlNlYXJjaCBieSBFbXBsb3llcnMiOiJTZWFyY2ggYnkgRW1wbG95ZXJzIiwiQ292aWQgMTkiOiJDb3ZpZCAxOSIsIkFsbCBKb2JzIjoiQWxsIEpvYnMiLCJKb2JzIGluIHtkZXNjcmlwdGlvbn0iOiJKb2JzIGluIHtkZXNjcmlwdGlvbn0iLCJKb2JzIGluIHtuYW1lfSI6IkpvYnMgaW4ge25hbWV9IiwiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIjoiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIiwiU2VhcmNoIGJ5IHJlY3J1aXRlciI6IlNlYXJjaCBieSByZWNydWl0ZXIifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJRdWljayBzZWFyY2giOiJRdWljayBzZWFyY2giLCJDbGFzc2lmaWNhdGlvbnMiOiJDbGFzc2lmaWNhdGlvbnMiLCJWaWV3IGFsbCI6IlZpZXcgYWxsIiwiTWFqb3IgY2l0aWVzIjoiTWFqb3IgY2l0aWVzIiwiT3RoZXIiOiJPdGhlciIsIkdyYWR1YXRlIC8gRW50cnkgTGV2ZWwiOiJHcmFkdWF0ZSAvIEVudHJ5IExldmVsIiwiVm9sdW50ZWVyIjoiVm9sdW50ZWVyIiwiU2VhcmNoIGJ5IHtrZXl3b3Jkc30iOiJ7a2V5d29yZHN9IEpvYnMiLCJTZWFyY2ggYnkgUmVjcnVpdGVyIjoiU2VhcmNoIGJ5IFJlY3J1aXRlciIsIlNlYXJjaCBieSBFbXBsb3llcnMiOiJTZWFyY2ggYnkgRW1wbG95ZXJzIiwiQ292aWQgMTkiOiJDb3ZpZCAxOSIsIkFsbCBKb2JzIjoiQWxsIEpvYnMiLCJKb2JzIGluIHtkZXNjcmlwdGlvbn0iOiJKb2JzIGluIHtkZXNjcmlwdGlvbn0iLCJKb2JzIGluIHtuYW1lfSI6IkpvYnMgaW4ge25hbWV9IiwiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIjoiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIiwiU2VhcmNoIGJ5IHJlY3J1aXRlciI6IlNlYXJjaCBieSByZWNydWl0ZXIifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJRdWljayBzZWFyY2giOiJRdWljayBzZWFyY2giLCJDbGFzc2lmaWNhdGlvbnMiOiJDbGFzc2lmaWNhdGlvbnMiLCJWaWV3IGFsbCI6IlZpZXcgYWxsIiwiTWFqb3IgY2l0aWVzIjoiTWFqb3IgY2l0aWVzIiwiT3RoZXIiOiJPdGhlciIsIkdyYWR1YXRlIC8gRW50cnkgTGV2ZWwiOiJHcmFkdWF0ZSAvIEVudHJ5IExldmVsIiwiVm9sdW50ZWVyIjoiVm9sdW50ZWVyIiwiU2VhcmNoIGJ5IHtrZXl3b3Jkc30iOiJ7a2V5d29yZHN9IEpvYnMiLCJTZWFyY2ggYnkgUmVjcnVpdGVyIjoiU2VhcmNoIGJ5IFJlY3J1aXRlciIsIlNlYXJjaCBieSBFbXBsb3llcnMiOiJTZWFyY2ggYnkgRW1wbG95ZXJzIiwiQ292aWQgMTkiOiJDb3ZpZCAxOSIsIkFsbCBKb2JzIjoiQWxsIEpvYnMiLCJKb2JzIGluIHtkZXNjcmlwdGlvbn0iOiJKb2JzIGluIHtkZXNjcmlwdGlvbn0iLCJKb2JzIGluIHtuYW1lfSI6IkpvYnMgaW4ge25hbWV9IiwiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIjoiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIiwiU2VhcmNoIGJ5IHJlY3J1aXRlciI6IlNlYXJjaCBieSByZWNydWl0ZXIifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJRdWljayBzZWFyY2giOiJQZW5jYXJpYW4gY2VwYXQiLCJDbGFzc2lmaWNhdGlvbnMiOiJLbGFzaWZpa2FzaSIsIlZpZXcgYWxsIjoiTGloYXQgc2VtdWEiLCJNYWpvciBjaXRpZXMiOiJLb3RhIGJlc2FyIiwiT3RoZXIiOiJMYWlubnlhIiwiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCI6IlRpbmdrYXQgQXdhbCAvIFNhcmphbmEiLCJWb2x1bnRlZXIiOiJTdWthcmVsYXdhbiIsIlNlYXJjaCBieSB7a2V5d29yZHN9IjoiTG93b25nYW4ga2VyamEge2tleXdvcmRzfSIsIlNlYXJjaCBieSBSZWNydWl0ZXIiOiJDYXJpIGJlcmRhc2Fya2FuIFBlcmVrcnV0IiwiU2VhcmNoIGJ5IEVtcGxveWVycyI6IkNhcmkgYmVyZGFzYXJrYW4gUGVtYmVyaSBLZXJqYSIsIkNvdmlkIDE5IjoiQ292aWQgMTkiLCJBbGwgSm9icyI6IlNlbXVhIFBla2VyamFhbiIsIkpvYnMgaW4ge2Rlc2NyaXB0aW9ufSI6Ikxvd29uZ2FuIGRpIHtkZXNjcmlwdGlvbn0iLCJKb2JzIGluIHtuYW1lfSI6Ikxvd29uZ2FuIGRpIHtuYW1lfSIsIkdyYWR1YXRlIC8gRW50cnkgTGV2ZWwgam9icyI6Ikxvd29uZ2FuIFRpbmdrYXQgQXdhbCAvIFNhcmphbmEiLCJTZWFyY2ggYnkgcmVjcnVpdGVyIjoiQ2FyaSBiZXJkYXNhcmthbiBwZXJla3J1dCJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJRdWljayBzZWFyY2giOiJQZW5jYXJpYW4gY2VwYXQiLCJDbGFzc2lmaWNhdGlvbnMiOiJLbGFzaWZpa2FzaSIsIlZpZXcgYWxsIjoiTGloYXQgc2VtdWEiLCJNYWpvciBjaXRpZXMiOiJLb3RhIGJlc2FyIiwiT3RoZXIiOiJMYWlubnlhIiwiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCI6IlRpbmdrYXQgQXdhbCAvIFNhcmphbmEiLCJWb2x1bnRlZXIiOiJTdWthcmVsYXdhbiIsIlNlYXJjaCBieSB7a2V5d29yZHN9IjoiTG93b25nYW4ga2VyamEge2tleXdvcmRzfSIsIlNlYXJjaCBieSBSZWNydWl0ZXIiOiJDYXJpIGJlcmRhc2Fya2FuIFBlcmVrcnV0IiwiU2VhcmNoIGJ5IEVtcGxveWVycyI6IkNhcmkgYmVyZGFzYXJrYW4gUGVtYmVyaSBLZXJqYSIsIkNvdmlkIDE5IjoiQ292aWQgMTkiLCJBbGwgSm9icyI6IlNlbXVhIFBla2VyamFhbiIsIkpvYnMgaW4ge2Rlc2NyaXB0aW9ufSI6Ikxvd29uZ2FuIGRpIHtkZXNjcmlwdGlvbn0iLCJKb2JzIGluIHtuYW1lfSI6Ikxvd29uZ2FuIGRpIHtuYW1lfSIsIkdyYWR1YXRlIC8gRW50cnkgTGV2ZWwgam9icyI6Ikxvd29uZ2FuIFRpbmdrYXQgQXdhbCAvIFNhcmphbmEiLCJTZWFyY2ggYnkgcmVjcnVpdGVyIjoiQ2FyaSBiZXJkYXNhcmthbiBwZXJla3J1dCJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJRdWljayBzZWFyY2giOiLguITguYnguJnguKvguLLguJTguYjguKfguJkiLCJDbGFzc2lmaWNhdGlvbnMiOiLguJvguKPguLDguYDguKDguJfguIfguLLguJkiLCJWaWV3IGFsbCI6IuC4lOC4ueC4l+C4seC5ieC4h+C4q+C4oeC4lCIsIk1ham9yIGNpdGllcyI6IuC5gOC4oeC4t+C4reC4h+C4quC4s+C4hOC4seC4jSIsIk90aGVyIjoi4Lit4Li34LmI4LiZIOC5hiIsIkdyYWR1YXRlIC8gRW50cnkgTGV2ZWwiOiLguJnguLHguIHguKjguLbguIHguKnguLLguIjguJrguYPguKvguKHguYgv4Lij4Liw4LiU4Lix4Lia4LmA4LiI4LmJ4Liy4Lir4LiZ4LmJ4Liy4LiX4Li14LmIIiwiVm9sdW50ZWVyIjoi4Lit4Liy4Liq4Liy4Liq4Lih4Lix4LiE4LijIiwiU2VhcmNoIGJ5IHtrZXl3b3Jkc30iOiLguIfguLLguJl7a2V5d29yZHN9IiwiU2VhcmNoIGJ5IFJlY3J1aXRlciI6IuC4hOC5ieC4meC4q+C4suC4iOC4suC4geC5gOC4iOC5ieC4suC4q+C4meC5ieC4suC4l+C4teC5iOC4quC4o+C4o+C4q+C4suC4muC4uOC4hOC4peC4suC4geC4oyIsIlNlYXJjaCBieSBFbXBsb3llcnMiOiLguITguYnguJnguKvguLLguIjguLLguIHguJzguLnguYnguJvguKPguLDguIHguK3guJrguIHguLLguKMiLCJDb3ZpZCAxOSI6IuC5guC4hOC4p+C4tOC4lCAxOSIsIkFsbCBKb2JzIjoi4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiSm9icyBpbiB7ZGVzY3JpcHRpb259Ijoi4LiH4Liy4LiZ4LmD4LiZe2Rlc2NyaXB0aW9ufSIsIkpvYnMgaW4ge25hbWV9Ijoi4LiH4Liy4LiZ4LmD4LiZe25hbWV9IiwiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIjoi4LiZ4Lix4LiB4Lio4Li24LiB4Lip4Liy4LiI4Lia4LmD4Lir4Lih4LmIL+C4h+C4suC4meC4o+C4sOC4lOC4seC4muC5gOC4iOC5ieC4suC4q+C4meC5ieC4suC4l+C4teC5iCIsIlNlYXJjaCBieSByZWNydWl0ZXIiOiLguITguYnguJnguKvguLLguIjguLLguIHguYDguIjguYnguLLguKvguJnguYnguLLguJfguLXguYjguJzguLnguYnguKrguKPguKPguKvguLLguJrguLjguITguKXguLLguIHguKMifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJRdWljayBzZWFyY2giOiLguITguYnguJnguKvguLLguJTguYjguKfguJkiLCJDbGFzc2lmaWNhdGlvbnMiOiLguJvguKPguLDguYDguKDguJfguIfguLLguJkiLCJWaWV3IGFsbCI6IuC4lOC4ueC4l+C4seC5ieC4h+C4q+C4oeC4lCIsIk1ham9yIGNpdGllcyI6IuC5gOC4oeC4t+C4reC4h+C4quC4s+C4hOC4seC4jSIsIk90aGVyIjoi4Lit4Li34LmI4LiZIOC5hiIsIkdyYWR1YXRlIC8gRW50cnkgTGV2ZWwiOiLguJnguLHguIHguKjguLbguIHguKnguLLguIjguJrguYPguKvguKHguYgv4Lij4Liw4LiU4Lix4Lia4LmA4LiI4LmJ4Liy4Lir4LiZ4LmJ4Liy4LiX4Li14LmIIiwiVm9sdW50ZWVyIjoi4Lit4Liy4Liq4Liy4Liq4Lih4Lix4LiE4LijIiwiU2VhcmNoIGJ5IHtrZXl3b3Jkc30iOiLguIfguLLguJl7a2V5d29yZHN9IiwiU2VhcmNoIGJ5IFJlY3J1aXRlciI6IuC4hOC5ieC4meC4q+C4suC4iOC4suC4geC5gOC4iOC5ieC4suC4q+C4meC5ieC4suC4l+C4teC5iOC4quC4o+C4o+C4q+C4suC4muC4uOC4hOC4peC4suC4geC4oyIsIlNlYXJjaCBieSBFbXBsb3llcnMiOiLguITguYnguJnguKvguLLguIjguLLguIHguJzguLnguYnguJvguKPguLDguIHguK3guJrguIHguLLguKMiLCJDb3ZpZCAxOSI6IuC5guC4hOC4p+C4tOC4lCAxOSIsIkFsbCBKb2JzIjoi4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiSm9icyBpbiB7ZGVzY3JpcHRpb259Ijoi4LiH4Liy4LiZ4LmD4LiZe2Rlc2NyaXB0aW9ufSIsIkpvYnMgaW4ge25hbWV9Ijoi4LiH4Liy4LiZ4LmD4LiZe25hbWV9IiwiR3JhZHVhdGUgLyBFbnRyeSBMZXZlbCBqb2JzIjoi4LiZ4Lix4LiB4Lio4Li24LiB4Lip4Liy4LiI4Lia4LmD4Lir4Lih4LmIL+C4h+C4suC4meC4o+C4sOC4lOC4seC4muC5gOC4iOC5ieC4suC4q+C4meC5ieC4suC4l+C4teC5iCIsIlNlYXJjaCBieSByZWNydWl0ZXIiOiLguITguYnguJnguKvguLLguIjguLLguIHguYDguIjguYnguLLguKvguJnguYnguLLguJfguLXguYjguJzguLnguYnguKrguKPguKPguKvguLLguJrguLjguITguKXguLLguIHguKMifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJRdWljayBzZWFyY2giOiJbUcyHx5rHmseaw6zDrMOsw6fEtyDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKldIiwiQ2xhc3NpZmljYXRpb25zIjoiW8OHxprEg8SDxaHFocOsw6zGksOsw6zDp8SDxIPhua/DrMOsw7bDtuC4geC4teC5icWhXSIsIlZpZXcgYWxsIjoiW+G5vMOsw6zDrOG6veG6veG6vcW1IMSDxIPEg8aaxppdIiwiTWFqb3IgY2l0aWVzIjoiW+G5gsSDxIPEtcO2w7bFmSDDp8Osw6zhua/DrMOs4bq94bq9xaFdIiwiT3RoZXIiOiJbw5bhua/huKnhur3hur3hur3FmV0iLCJHcmFkdWF0ZSAvIEVudHJ5IExldmVsIjoiW8emxZnEg8SDxIPGjMeax5rHmsSDxIPEg+G5r+G6veG6veG6vSAvIMOL4LiB4Li14LmJ4bmvxZnDvcO9w70gxYHhur3hur3hur3hub3hur3hur3hur3Gml0iLCJWb2x1bnRlZXIiOiJb4bm8w7bDtsaax5rHmuC4geC4teC5ieG5r+G6veG6veG6veG6vcWZXSIsIlNlYXJjaCBieSB7a2V5d29yZHN9IjoiW3trZXl3b3Jkc30gxLTDtsO2w7bDn8WhXSIsIlNlYXJjaCBieSBSZWNydWl0ZXIiOiJbxaDhur3hur3hur3Eg8SDxIPFmcOn4bipIMOfw73DvcO9IMWY4bq94bq94bq9w6fFmceax5rHmsOsw6zDrOG5r+G6veG6veG6vcWZXSIsIlNlYXJjaCBieSBFbXBsb3llcnMiOiJbxaDhur3hur3hur3Eg8SDxIPFmcOn4bipIMOfw73DvcO9IMOLbcyCxqXGmsO2w7bDtsO9w73DveG6veG6veG6vcWZxaFdIiwiQ292aWQgMTkiOiJbw4fDtsO2w7bhub3DrMOsw6zGjCAxOV0iLCJBbGwgSm9icyI6IlvhuqzGmsaaIMS0w7bDtsO2w7bDtsOfxaFdIiwiSm9icyBpbiB7ZGVzY3JpcHRpb259IjoiW8S0w7bDtsO2w5/FoSDDrMOsw6zguIHguLXguYkge2Rlc2NyaXB0aW9ufV0iLCJKb2JzIGluIHtuYW1lfSI6IlvEtMO2w7bDtsOfxaEgw6zDrMOs4LiB4Li14LmJIHtuYW1lfV0iLCJHcmFkdWF0ZSAvIEVudHJ5IExldmVsIGpvYnMiOiJbx6bFmcSDxIPEg8aMx5rHmseaxIPEg8SD4bmv4bq94bq94bq9IC8gw4vguIHguLXguYnhua/FmcO9w73DvSDFgeG6veG6veG6veG5veG6veG6veG6vcaaIMS1w7bDtsO2w5/FoV0iLCJTZWFyY2ggYnkgcmVjcnVpdGVyIjoiW8Wg4bq94bq94bq9xIPEg8SDxZnDp+G4qSDDn8O9w73DvSDFmeG6veG6veG6vcOnxZnHmseax5rDrMOsw6zhua/hur3hur3hur3FmV0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJRdWljayBzZWFyY2giOiJbUcyHx5rHmseaw6zDrMOsw6fEtyDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKldIiwiQ2xhc3NpZmljYXRpb25zIjoiW8OHxprEg8SDxaHFocOsw6zGksOsw6zDp8SDxIPhua/DrMOsw7bDtuC4geC4teC5icWhXSIsIlZpZXcgYWxsIjoiW+G5vMOsw6zDrOG6veG6veG6vcW1IMSDxIPEg8aaxppdIiwiTWFqb3IgY2l0aWVzIjoiW+G5gsSDxIPEtcO2w7bFmSDDp8Osw6zhua/DrMOs4bq94bq9xaFdIiwiT3RoZXIiOiJbw5bhua/huKnhur3hur3hur3FmV0iLCJHcmFkdWF0ZSAvIEVudHJ5IExldmVsIjoiW8emxZnEg8SDxIPGjMeax5rHmsSDxIPEg+G5r+G6veG6veG6vSAvIMOL4LiB4Li14LmJ4bmvxZnDvcO9w70gxYHhur3hur3hur3hub3hur3hur3hur3Gml0iLCJWb2x1bnRlZXIiOiJb4bm8w7bDtsaax5rHmuC4geC4teC5ieG5r+G6veG6veG6veG6vcWZXSIsIlNlYXJjaCBieSB7a2V5d29yZHN9IjoiW3trZXl3b3Jkc30gxLTDtsO2w7bDn8WhXSIsIlNlYXJjaCBieSBSZWNydWl0ZXIiOiJbxaDhur3hur3hur3Eg8SDxIPFmcOn4bipIMOfw73DvcO9IMWY4bq94bq94bq9w6fFmceax5rHmsOsw6zDrOG5r+G6veG6veG6vcWZXSIsIlNlYXJjaCBieSBFbXBsb3llcnMiOiJbxaDhur3hur3hur3Eg8SDxIPFmcOn4bipIMOfw73DvcO9IMOLbcyCxqXGmsO2w7bDtsO9w73DveG6veG6veG6vcWZxaFdIiwiQ292aWQgMTkiOiJbw4fDtsO2w7bhub3DrMOsw6zGjCAxOV0iLCJBbGwgSm9icyI6IlvhuqzGmsaaIMS0w7bDtsO2w7bDtsOfxaFdIiwiSm9icyBpbiB7ZGVzY3JpcHRpb259IjoiW8S0w7bDtsO2w5/FoSDDrMOsw6zguIHguLXguYkge2Rlc2NyaXB0aW9ufV0iLCJKb2JzIGluIHtuYW1lfSI6IlvEtMO2w7bDtsOfxaEgw6zDrMOs4LiB4Li14LmJIHtuYW1lfV0iLCJHcmFkdWF0ZSAvIEVudHJ5IExldmVsIGpvYnMiOiJbx6bFmcSDxIPEg8aMx5rHmseaxIPEg8SD4bmv4bq94bq94bq9IC8gw4vguIHguLXguYnhua/FmcO9w73DvSDFgeG6veG6veG6veG5veG6veG6veG6vcaaIMS1w7bDtsO2w5/FoV0iLCJTZWFyY2ggYnkgcmVjcnVpdGVyIjoiW8Wg4bq94bq94bq9xIPEg8SDxZnDp+G4qSDDn8O9w73DvSDFmeG6veG6veG6vcOnxZnHmseax5rDrMOsw6zhua/hur3hur3hur3FmV0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    