
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwie2NvdW50fSBuZXciOiJ7Y291bnR9IG5ldyIsIlNhdmVkIHNlYXJjaGVzIjoiU2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwie2NvdW50fSBuZXciOiJ7Y291bnR9IG5ldyIsIlNhdmVkIHNlYXJjaGVzIjoiU2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwie2NvdW50fSBuZXciOiJ7Y291bnR9IG5ldyIsIlNhdmVkIHNlYXJjaGVzIjoiU2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwie2NvdW50fSBuZXciOiJ7Y291bnR9IG5ldyIsIlNhdmVkIHNlYXJjaGVzIjoiU2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwie2NvdW50fSBuZXciOiJ7Y291bnR9IG5ldyIsIlNhdmVkIHNlYXJjaGVzIjoiU2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwie2NvdW50fSBuZXciOiJ7Y291bnR9IG5ldyIsIlNhdmVkIHNlYXJjaGVzIjoiU2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwie2NvdW50fSBuZXciOiJ7Y291bnR9IG5ldyIsIlNhdmVkIHNlYXJjaGVzIjoiU2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwie2NvdW50fSBuZXciOiJ7Y291bnR9IG5ldyIsIlNhdmVkIHNlYXJjaGVzIjoiU2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwie2NvdW50fSBuZXciOiJ7Y291bnR9IG5ldyIsIlNhdmVkIHNlYXJjaGVzIjoiU2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwie2NvdW50fSBuZXciOiJ7Y291bnR9IG5ldyIsIlNhdmVkIHNlYXJjaGVzIjoiU2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwie2NvdW50fSBuZXciOiJ7Y291bnR9IG5ldyIsIlNhdmVkIHNlYXJjaGVzIjoiU2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwie2NvdW50fSBuZXciOiJ7Y291bnR9IG5ldyIsIlNhdmVkIHNlYXJjaGVzIjoiU2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwie2NvdW50fSBuZXciOiJ7Y291bnR9IG5ldyIsIlNhdmVkIHNlYXJjaGVzIjoiU2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwie2NvdW50fSBuZXciOiJ7Y291bnR9IG5ldyIsIlNhdmVkIHNlYXJjaGVzIjoiU2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwie2NvdW50fSBuZXciOiJ7Y291bnR9IG5ldyIsIlNhdmVkIHNlYXJjaGVzIjoiU2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwie2NvdW50fSBuZXciOiJ7Y291bnR9IG5ldyIsIlNhdmVkIHNlYXJjaGVzIjoiU2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwie2NvdW50fSBuZXciOiJ7Y291bnR9IG5ldyIsIlNhdmVkIHNlYXJjaGVzIjoiU2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwie2NvdW50fSBuZXciOiJ7Y291bnR9IG5ldyIsIlNhdmVkIHNlYXJjaGVzIjoiU2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwie2NvdW50fSBuZXciOiJ7Y291bnR9IG5ldyIsIlNhdmVkIHNlYXJjaGVzIjoiU2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwie2NvdW50fSBuZXciOiJ7Y291bnR9IG5ldyIsIlNhdmVkIHNlYXJjaGVzIjoiU2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwie2NvdW50fSBuZXciOiJ7Y291bnR9IG5ldyIsIlNhdmVkIHNlYXJjaGVzIjoiU2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBmb3I6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9Iiwie2NvdW50fSBuZXciOiJ7Y291bnR9IG5ldyIsIlNhdmVkIHNlYXJjaGVzIjoiU2F2ZWQgc2VhcmNoZXMiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiVXNlIHRoZSBTYXZlIHNlYXJjaCBidXR0b24gYmVsb3cgdGhlIHNlYXJjaCByZXN1bHRzIHRvIHNhdmUgeW91ciBzZWFyY2ggYW5kIHJlY2VpdmUgZXZlcnkgbmV3IGpvYi4ifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7bG93b25nYW4ga2VyamF9IG9uZSB7bG93b25nYW4ga2VyamF9IG90aGVyIHtsb3dvbmdhbiBrZXJqYX19IGZvcjo8L0hpZGRlblZpc3VhbGx5PiB7bmFtZX0iLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gYmFydSIsIlNhdmVkIHNlYXJjaGVzIjoiUGVuY2FyaWFuIHRlcnNpbXBhbiIsIlVzZSB0aGUgU2F2ZSBzZWFyY2guLi4iOiJHdW5ha2FuIHRvbWJvbCBTaW1wYW4gcGVuY2FyaWFuIGRpIGJhd2FoIGhhc2lsIHBlbmNhcmlhbiB1bnR1ayBtZW55aW1wYW4gcGVuY2FyaWFuIGRhbiBtZW5lcmltYSBzZXRpYXAgbG93b25nYW4ga2VyamEgYmFydS4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0gbmV3IHtjb3VudCwgcGx1cmFsLCA9MCB7bG93b25nYW4ga2VyamF9IG9uZSB7bG93b25nYW4ga2VyamF9IG90aGVyIHtsb3dvbmdhbiBrZXJqYX19IGZvcjo8L0hpZGRlblZpc3VhbGx5PiB7bmFtZX0iLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gYmFydSIsIlNhdmVkIHNlYXJjaGVzIjoiUGVuY2FyaWFuIHRlcnNpbXBhbiIsIlVzZSB0aGUgU2F2ZSBzZWFyY2guLi4iOiJHdW5ha2FuIHRvbWJvbCBTaW1wYW4gcGVuY2FyaWFuIGRpIGJhd2FoIGhhc2lsIHBlbmNhcmlhbiB1bnR1ayBtZW55aW1wYW4gcGVuY2FyaWFuIGRhbiBtZW5lcmltYSBzZXRpYXAgbG93b25nYW4ga2VyamEgYmFydS4ifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0g4LmD4Lir4Lih4LmIIHtjb3VudCwgcGx1cmFsLCA9MCB74LiH4Liy4LiZfSBvbmUge+C4h+C4suC4mX0gb3RoZXIge+C4h+C4suC4mX19IOC4quC4s+C4q+C4o+C4seC4mjo8L0hpZGRlblZpc3VhbGx5PiB7bmFtZX0iLCJ7Y291bnR9IG5ldyI6Intjb3VudH0g4LmD4Lir4Lih4LmIIiwiU2F2ZWQgc2VhcmNoZXMiOiLguJrguLHguJnguJfguLbguIHguIHguLLguKPguITguYnguJnguKvguLIiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoi4LmD4LiK4LmJ4Lib4Li44LmI4Lih4Lia4Lix4LiZ4LiX4Li24LiB4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiU4LmJ4Liy4LiZ4Lil4LmI4Liy4LiH4Lic4Lil4Lil4Lix4Lie4LiY4LmM4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4LiyIOC5gOC4nuC4t+C5iOC4reC4muC4seC4meC4l+C4tuC4geC4nOC4peC4geC4suC4o+C4hOC5ieC4meC4q+C4suC5geC4peC4sOC4o+C4seC4muC4geC4suC4o+C4reC4seC4m+C5gOC4lOC4leC4leC4s+C5geC4q+C4meC5iOC4h+C4h+C4suC4meC5g+C4q+C4oeC5iCJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6IjxIaWRkZW5WaXN1YWxseT57Y291bnRMYWJlbH0g4LmD4Lir4Lih4LmIIHtjb3VudCwgcGx1cmFsLCA9MCB74LiH4Liy4LiZfSBvbmUge+C4h+C4suC4mX0gb3RoZXIge+C4h+C4suC4mX19IOC4quC4s+C4q+C4o+C4seC4mjo8L0hpZGRlblZpc3VhbGx5PiB7bmFtZX0iLCJ7Y291bnR9IG5ldyI6Intjb3VudH0g4LmD4Lir4Lih4LmIIiwiU2F2ZWQgc2VhcmNoZXMiOiLguJrguLHguJnguJfguLbguIHguIHguLLguKPguITguYnguJnguKvguLIiLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoi4LmD4LiK4LmJ4Lib4Li44LmI4Lih4Lia4Lix4LiZ4LiX4Li24LiB4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiU4LmJ4Liy4LiZ4Lil4LmI4Liy4LiH4Lic4Lil4Lil4Lix4Lie4LiY4LmM4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4LiyIOC5gOC4nuC4t+C5iOC4reC4muC4seC4meC4l+C4tuC4geC4nOC4peC4geC4suC4o+C4hOC5ieC4meC4q+C4suC5geC4peC4sOC4o+C4seC4muC4geC4suC4o+C4reC4seC4m+C5gOC4lOC4leC4leC4s+C5geC4q+C4meC5iOC4h+C4h+C4suC4meC5g+C4q+C4oeC5iCJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6Ils8SGlkZGVuVmlzdWFsbHk+e2NvdW50TGFiZWx9IOC4geC4teC5ieG6veG6veG6vcW1IHtjb3VudCxwbHVyYWwsPTB7xLXDtsO2w7bDn30gb25le8S1w7bDtsO2w599IG90aGVye8S1w7bDtsO2w5/FoX19IMaSw7bDtsO2xZk6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9XSIsIntjb3VudH0gbmV3IjoiW3tjb3VudH0g4LiB4Li14LmJ4bq94bq94bq9xbVdIiwiU2F2ZWQgc2VhcmNoZXMiOiJbxaDEg8SDxIPhub3hur3hur3hur3GjCDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKnhur3hur3hur3FoV0iLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiW8eZxaHhur3hur3hur0g4bmv4bip4bq94bq94bq9IMWgxIPEg8SD4bm94bq94bq94bq9IMWh4bq94bq94bq9xIPEg8SDxZnDp+G4qSDDn8eax5rHmuG5r+G5r8O2w7bDtuC4geC4teC5iSDDn+G6veG6veG6vcaaw7bDtsO2xbUg4bmv4bip4bq94bq94bq9IMWh4bq94bq94bq9xIPEg8SDxZnDp+G4qSDFmeG6veG6veG6vcWhx5rHmseaxprhua/FoSDhua/DtsO2w7YgxaHEg8SDxIPhub3hur3hur3hur0gw73DvcO9w7bDtsO2x5rHmseaxZkgxaHhur3hur3hur3Eg8SDxIPFmcOn4bipIMSDxIPEg+C4geC4teC5icaMIMWZ4bq94bq94bq9w6fhur3hur3hur3DrMOsw6zhub3hur3hur3hur0g4bq94bq94bq94bm94bq94bq94bq9xZnDvcO9w70g4LiB4Li14LmJ4bq94bq94bq9xbUgxLXDtsO2w7bDny5dIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y291bnRMYWJlbH0gbmV3IGpvYihzKSBmb3I6IHtuYW1lfSI6Ils8SGlkZGVuVmlzdWFsbHk+e2NvdW50TGFiZWx9IOC4geC4teC5ieG6veG6veG6vcW1IHtjb3VudCxwbHVyYWwsPTB7xLXDtsO2w7bDn30gb25le8S1w7bDtsO2w599IG90aGVye8S1w7bDtsO2w5/FoX19IMaSw7bDtsO2xZk6PC9IaWRkZW5WaXN1YWxseT4ge25hbWV9XSIsIntjb3VudH0gbmV3IjoiW3tjb3VudH0g4LiB4Li14LmJ4bq94bq94bq9xbVdIiwiU2F2ZWQgc2VhcmNoZXMiOiJbxaDEg8SDxIPhub3hur3hur3hur3GjCDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKnhur3hur3hur3FoV0iLCJVc2UgdGhlIFNhdmUgc2VhcmNoLi4uIjoiW8eZxaHhur3hur3hur0g4bmv4bip4bq94bq94bq9IMWgxIPEg8SD4bm94bq94bq94bq9IMWh4bq94bq94bq9xIPEg8SDxZnDp+G4qSDDn8eax5rHmuG5r+G5r8O2w7bDtuC4geC4teC5iSDDn+G6veG6veG6vcaaw7bDtsO2xbUg4bmv4bip4bq94bq94bq9IMWh4bq94bq94bq9xIPEg8SDxZnDp+G4qSDFmeG6veG6veG6vcWhx5rHmseaxprhua/FoSDhua/DtsO2w7YgxaHEg8SDxIPhub3hur3hur3hur0gw73DvcO9w7bDtsO2x5rHmseaxZkgxaHhur3hur3hur3Eg8SDxIPFmcOn4bipIMSDxIPEg+C4geC4teC5icaMIMWZ4bq94bq94bq9w6fhur3hur3hur3DrMOsw6zhub3hur3hur3hur0g4bq94bq94bq94bm94bq94bq94bq9xZnDvcO9w70g4LiB4Li14LmJ4bq94bq94bq9xbUgxLXDtsO2w7bDny5dIn0=!"
        )
      )
      });
  
      export { translations as default };
    