import { useTranslations } from '@vocab/react';
import {
  Column,
  Columns,
  Hidden,
  HiddenVisually,
  Stack,
} from 'braid-design-system';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectFeatureFlag } from 'src/store/featureFlags/selectors';

import { CPCarousel } from '../../CPCarousel/CPCarousel';

import translations from './.vocab';
import { MoreRecommendations } from './DashboardRecommendations/MoreRecommendations';
import { TopRecommendations } from './DashboardRecommendations/TopRecommendations';
import SavedSearches from './DashboardSaveSearches/SavedSearches';
import SavedJobs from './DashboardSavedJobs/SavedJobs';
import { JobDetailsViewDrawer } from './SharedComponent/JobDetailsViewDrawer/JobDetailsViewDrawer';
import { SignedInDashboardContextProvider } from './SignedInDashboardContextProvider';

const SignedInDashboard = () => {
  const { t } = useTranslations(translations);
  const [companyCarouselError, setCompanyCarouselError] = useState(false);
  const homepageJdvDrawer = useSelector(selectFeatureFlag('homepageJdvDrawer'));

  useEffect(() => {
    window.hj ||= function () {
      // eslint-disable-next-line prefer-rest-params
      (hj.q = hj.q || []).push(arguments);
    };

    window.hj('event', 'homepage_layout_refresh_experiment');
  }, []);

  return (
    <SignedInDashboardContextProvider>
      <HiddenVisually>
        <h1>{t('Dashboard')}</h1>
      </HiddenVisually>

      <Columns space="xlarge">
        <Column>
          <Stack
            space={{
              desktop: companyCarouselError ? 'xsmall' : 'xlarge',
              mobile: 'xlarge',
            }}
          >
            <TopRecommendations />
            <Hidden above="tablet">
              <SavedSearches />
            </Hidden>
            <Hidden above="tablet">
              <SavedJobs />
            </Hidden>
            <CPCarousel
              sectionSpace="none"
              preserveSpace={false}
              homepageLayoutRefresh={true}
              onError={() => {
                setCompanyCarouselError(true);
              }}
            />
            <MoreRecommendations />
          </Stack>
        </Column>
        <Column width="2/5" hideBelow="desktop">
          <Stack space="large">
            <SavedSearches />
            <SavedJobs />
          </Stack>
        </Column>
      </Columns>
      {homepageJdvDrawer && <JobDetailsViewDrawer />}
    </SignedInDashboardContextProvider>
  );
};

export default memo(SignedInDashboard);
