import type { UrlLocation } from '@seek/chalice-types';
import { Text } from 'braid-design-system';
import type { ReactNode, MouseEvent } from 'react';

import { TextLink } from 'src/components/NavLink/NavLink';

export interface QuickSearchLinkProps {
  children?: ReactNode;
  linkProps: {
    ['aria-label']?: string;
    ['data-automation']?: string;
    analytics?: string;
    location: UrlLocation | string;
    rel?: string;
    onClick?: (event: MouseEvent) => void;
  };
}

export const QuickSearchLink = ({
  children,
  linkProps,
}: QuickSearchLinkProps) => (
  <Text size="small" tone="secondary">
    <TextLink weight="weak" {...linkProps}>
      {children}
    </TextLink>
  </Text>
);
