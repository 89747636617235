import type { UrlLocation } from '@seek/chalice-types';
import { stringify } from 'query-string';
import { useMemo } from 'react';

import { registerPath, signInPath, signInRegUrlPrefix } from 'src/config';
import { useSelector } from 'src/store/react';
import { selectLocation } from 'src/store/selectors';

export type LoginMode = 'signin' | 'register';

interface ReturnUrlParams {
  mode: LoginMode;
  targetPath?: UrlLocation['pathname'];
  query?: UrlLocation['query'];
}

const pathForMode: Record<LoginMode, string> = {
  signin: `${signInRegUrlPrefix}${signInPath}`,
  register: `${signInRegUrlPrefix}${registerPath}`,
};

const renderReturnUrl = (
  queryString: string,
  currentPath: string,
  targetPath?: string,
) => `${(targetPath ?? currentPath) || ''}${renderQueryString(queryString)}`;

const renderQueryString = (queryString: string) =>
  queryString ? `?${queryString}` : '';

export const useReturnUrl = ({
  mode,
  targetPath,
  query = {},
}: ReturnUrlParams) => {
  const currentLocation = useSelector(selectLocation);

  const pathWithReturnUrl = useMemo(() => {
    const { pathname: currentPath, query: currentQuery } = currentLocation;
    const queryString = stringify({ ...currentQuery, ...query } || {});
    const returnUrl = renderReturnUrl(queryString, currentPath, targetPath);
    return `${pathForMode[mode]}?returnUrl=${encodeURIComponent(returnUrl)}`;
  }, [currentLocation, mode, targetPath, query]);

  return pathWithReturnUrl;
};
