import { useTranslations } from '@vocab/react';
import { Stack, Text, PageBlock } from 'braid-design-system';
import { memo } from 'react';

import translations from './.vocab';
import { Classifications, MajorCities, Other } from './components';
import { useQuickSearch } from './hooks/useQuickSearch';

const QuickSearch = () => {
  const { t } = useTranslations(translations);
  const { links, ...commonProps } = useQuickSearch();

  return (
    <PageBlock
      component="section"
      data={{ automation: 'quick-search-container' }}
    >
      <Stack space="xsmall">
        <Text size="standard" component="h1" weight="medium">
          {t('Quick search')}
        </Text>
        <Classifications {...commonProps} />
        <MajorCities {...commonProps} links={links.majorCities} />
        <Other {...commonProps} links={links.other} />
      </Stack>
    </PageBlock>
  );
};

export default memo(QuickSearch);
