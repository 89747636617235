import { metrics } from '@seek/metrics-js';
import { useEffect } from 'react';

import { useSelector } from 'src/store/react';
import { selectPrevPathname } from 'src/store/selectors';

import { browserLogError, browserLogInfo } from '../logger/browser-logs';

/**
 * Measure the time it takes for a component to mount since the response end.
 * This is useful for measuring the time it takes for a component to mount after the initial page load.
 *
 * The hook will only publish the metric only on the initial rendering from server side and the component is hydrated.
 * (eg: to avoid the case of measuring the time it takes for a component to mount after a client-side navigation)
 *
 * @param componentName The name of the component to measure the time for.
 */

const metricSent: Record<string, boolean> = {};

const useMeasureTimeFirstMountFromResponseEnd = (
  componentName: string,
  hasLoaded: boolean = true,
) => {
  const previousPath = useSelector(selectPrevPathname);

  useEffect(() => {
    if (hasLoaded) {
      // Only measure the time for the first time rendering (aka being rendered first time on the client side and therefore no previous path)
      if (metricSent[componentName] || previousPath) {
        return;
      }

      metricSent[componentName] = true;

      const startTime = getResponseEndStartTime();
      if (!startTime) {
        return;
      }

      const duration = new Date().valueOf() - startTime;

      // Will be removed after we have enough data
      debugWhyDurationCanTakeAnHour(duration);

      metrics.timing('component.timeToFirstMountFromResponseEnd', duration, [
        `component:${componentName}`,
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLoaded]);
};

const debugWhyDurationCanTakeAnHour = (duration: number) => {
  try {
    if (duration > 60 * 60 * 1000) {
      const performanceNavigationTiming =
        performance?.getEntriesByType('navigation')?.[0];

      browserLogInfo('Suspiciously long duration', {
        input: {
          roughNow: new Date().valueOf(),
          windowPerformanceTimeOrigin: window.performance?.timeOrigin,
          performanceNavigationTiming: JSON.stringify(
            performanceNavigationTiming,
          ),
        },
      });
    }
  } catch (e) {
    browserLogError(e as Error, {
      input: { source: 'debugWhyDurationCanTakeAnHour' },
    });
  }
};

/**
 * returns the number of milliseconds for when the response end
 * time finished (after the html doc is downloaded to client) since the epoch.
 *
 * Returns null if the window object/navigation timing is not available or if
 * time origin data is not reliable.
 */
const getResponseEndStartTime = () => {
  if (!window) {
    return null;
  }

  const timeOrigin = window.performance?.timeOrigin;
  const performanceNow = window.performance?.now();
  const performanceNavigationTiming = window.performance?.getEntriesByType(
    'navigation',
  )?.[0] as PerformanceNavigationTiming;

  if (
    !timeOrigin ||
    !performanceNavigationTiming ||
    !performanceNavigationTiming.responseEnd ||
    !performanceNow
  ) {
    return null;
  }

  // Unfortunately browsers may report an inaccurate time origin data due to a bug
  // in the earlier version of browser: https://issues.chromium.org/issues/40866530
  // Verifying if time origin data is reliable by checking if the data
  // are within a reasonable threshold of the current time.
  const threshold = 60 * 60 * 1000;
  const timeOriginDelta = Math.abs(timeOrigin + performanceNow - Date.now());
  const timeOriginIsReliable = timeOriginDelta < threshold;

  if (!timeOriginIsReliable) {
    return null;
  }

  return timeOrigin + performanceNavigationTiming.responseEnd;
};

export default useMeasureTimeFirstMountFromResponseEnd;
