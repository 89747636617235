import { useTranslations } from '@vocab/react';
import {
  Box,
  Heading,
  IconChevron,
  Inline,
  PageBlock,
} from 'braid-design-system';
import { useEffect, useState } from 'react';

import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { isMobileUserAgent } from 'src/modules/chalice-user-agent/device-detect';
import { useSelector } from 'src/store/react';
import { selectRecentSearches } from 'src/store/selectors';

import translations from './.vocab';
import RecentSearchItem from './components/RecentSearchItem';

const RecentSearchesBar = () => {
  const analyticsFacade = useAnalyticsFacade();

  const [isExpanded, setIsExpanded] = useState(false);

  const searchItems = useSelector(selectRecentSearches);
  const isMobileOrTablet = isMobileUserAgent();
  const { t } = useTranslations(translations);

  useEffect(() => {
    analyticsFacade.recentSearchesImpression({
      cardDisplayedCount: `${searchItems?.length ?? 0}`,
      recentSearchesCollapsed: isMobileOrTablet,
      isExitLink: false,
    });
  }, [analyticsFacade, isMobileOrTablet, searchItems]);

  if (!searchItems?.length) {
    return null;
  }

  return (
    <PageBlock data={{ automation: 'recent-searches-bar' }}>
      <Box
        component="button"
        textAlign="left"
        width="full"
        cursor="pointer"
        onClick={() => setIsExpanded(!isExpanded)}
        marginTop={{ mobile: 'xlarge' }}
        display={{
          tablet: searchItems.length >= 3 ? 'block' : 'none',
          desktop: 'none',
        }}
      >
        <Heading level="3">
          <Box
            component="span"
            display="flex"
            justifyContent="spaceBetween"
            alignItems="center"
          >
            {t('Recent searches')}{' '}
            <IconChevron direction={isExpanded ? 'up' : 'down'} />
          </Box>
        </Heading>
      </Box>

      <Box
        display={{ mobile: isExpanded ? 'block' : 'none', desktop: 'block' }}
        paddingTop={{
          mobile: isExpanded ? 'medium' : 'none',
          desktop: 'gutter',
        }}
      >
        <Inline space="xsmall">
          {searchItems.map((item, index) =>
            item ? (
              <Box key={index} data-automation={`recent-search-item-${index}`}>
                <RecentSearchItem
                  search={item}
                  listIndex={index + 1}
                  listDisplayCount={searchItems.length}
                />
              </Box>
            ) : null,
          )}
        </Inline>
      </Box>
    </PageBlock>
  );
};

export default RecentSearchesBar;
