import type { LinkContext } from '@seek/seek-jobs-analytics/lib-types/mappers/link';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { Box, Heading, Inline, Stack, Text } from 'braid-design-system';
import type { ComponentProps, ReactNode } from 'react';

import { ButtonLink, Link } from 'src/components/NavLink/NavLink';

import * as styles from './Campaign.css';

export interface CampaignProps {
  type: 'multi-campaign' | 'standalone';
  background: {
    color: string;
    imageUrl: string;
  };
  text: {
    title: ReactNode | string;
    subTitle?: {
      label?: ReactNode | string;
      imageUrl?: string;
      color?: string;
    };
    inverted?: boolean;
  };
  reverseLayout?: boolean;
  subTitle?: ReactNode | string;
  alignRight?: boolean;
  cta?: {
    label: string;
    variant?: ComponentProps<typeof ButtonLink>['variant'];
    tone?: ComponentProps<typeof ButtonLink>['tone'];
    inverted?: boolean;
    linkContext?: LinkContext;
  };
  location?: string;
  analytics?: string;
}

const Campaign = ({
  text: { title, subTitle, inverted },
  background,
  type,
  cta,
  reverseLayout,
  location,
  analytics,
}: CampaignProps) => {
  const isStandalone = type === 'standalone';

  const content = (
    <Box
      height="full"
      borderRadius="xlarge"
      overflow="hidden"
      background={inverted ? 'customDark' : undefined}
      className={[
        styles.background,
        isStandalone ? styles.standaloneCampaign : styles.multiCampaign,
        reverseLayout ? styles.reverse : undefined,
      ]}
      style={assignInlineVars({
        [styles.backgroundColour]: background.color,
        [styles.backgroundImage]: `url('${background.imageUrl}')`,
        [styles.subtitleColour]: subTitle?.color || '',
      })}
    >
      <Box display="flex" height="full" className={styles.containerLayout}>
        <Box flexGrow={1} minWidth={0} width="full">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="spaceBetween"
            height="full"
            className={styles.contentGutter}
          >
            {title || subTitle ? (
              <Stack space={isStandalone ? 'medium' : 'small'}>
                {title ? (
                  <Text weight="strong">
                    <Box display="block" className={styles.title}>
                      {title}
                    </Box>
                  </Text>
                ) : null}
                {subTitle && subTitle.imageUrl && isStandalone ? (
                  <Box
                    className={styles.imageSubtitle}
                    style={assignInlineVars({
                      [styles.subtitleBackgroundImage]: `url('${subTitle.imageUrl}')`,
                    })}
                  />
                ) : null}
                {subTitle && subTitle.label && isStandalone ? (
                  <Heading level="2">
                    <span className={styles.subtitle}>{subTitle.label}</span>
                  </Heading>
                ) : null}
                {subTitle && !isStandalone ? (
                  <Text size="large">
                    <span className={styles.subtitle}>{subTitle.label}</span>
                  </Text>
                ) : null}
              </Stack>
            ) : null}
            {cta && location ? (
              <Box
                paddingTop={title || subTitle ? 'gutter' : undefined}
                background={cta.inverted ? 'customDark' : undefined}
              >
                <Inline space="none">
                  <ButtonLink
                    variant={cta?.variant}
                    tone={cta?.tone}
                    location={location}
                    analytics={analytics}
                    linkContext={cta.linkContext}
                  >
                    <span className={styles.nowrap}>{cta.label}</span>
                  </ButtonLink>
                </Inline>
              </Box>
            ) : null}
          </Box>
        </Box>
        <Box flexGrow={1} minWidth={0} width="full">
          <Box className={styles.image} />
        </Box>
      </Box>
    </Box>
  );

  return isStandalone || (cta && location) ? (
    content
  ) : (
    <Link location={location} analytics={analytics}>
      {content}
    </Link>
  );
};

export default Campaign;
